import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-work-info',
  templateUrl: './work-info.component.html',
  styleUrls: ['./work-info.component.scss']
})
export class WorkInfoComponent implements OnInit {
  @Input() staffId = '';
  @Input() workSince = '';
  @Input() designation = '';
  @Input() department = '';
  @Input() isEditable = false;
  @Input() isProfile = true;
  @Output() workInfoChanged = new EventEmitter<
    { staffId: '', workSince: '', designation: '', department: '', isEditable: ''}
  >();
  departmentOptions = [
    {value: '', viewValue: ''},
    {value: 'A', viewValue: 'd sah dsuihdsiuhdsiaisa uhdauihiusahdi aA'},
  ];
  workInfoForm: FormGroup;
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.workInfoForm = this.fb.group({
      staffId: [''],
      workSince: [''],
      designation: [''],
      department: [''],
    });
  }

  editWorkInfo() {
    this.isEditable = true;
    this.workInfoForm.patchValue({
      staffId: this.staffId,
      workSince: this.workSince,
      designation: this.designation,
      department: this.department,
    });
  }

  cancel() {
    this.isEditable = false;
    this.workInfoForm.reset();
    this.removeValidation();
  }

  saveChanges() {
    this.setValidation();
    this.workInfoForm.markAllAsTouched();

    console.log(this.workInfoForm.valid);
    
    if (this.workInfoForm.valid) {
      this.isEditable = false;
      this.workInfoChanged.emit(this.workInfoForm.getRawValue());

      this.workInfoForm.reset();
      this.removeValidation();
    }
  }

  setValidation() {
    this.departmentInput.setValidators([Validators.required]);
    this.departmentInput.updateValueAndValidity();
  }

  removeValidation() {
    this.departmentInput.clearValidators();
    this.departmentInput.updateValueAndValidity();

  }

  get workSinceInput() {return this.workInfoForm.get('workSince'); }
  get designationInput() { return this.workInfoForm.get('designation'); }
  get departmentInput() { return this.workInfoForm.get('department'); }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-wip',
  templateUrl: './wip.component.html',
  styleUrls: ['./wip.component.scss']
})
export class WipComponent implements OnInit {

  score = 0;

  constructor() { }

  ngOnInit(): void {
   
  }

 
 
}

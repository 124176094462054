import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { SelectBoxComponent } from '../base/select-box/select-box.component';

@Component({
  selector: 'app-label-select',
  templateUrl: './label-select.component.html',
  styleUrls: ['../base/select-box/select-box.component.scss', './label-select.component.scss']
})
export class LabelSelectComponent extends SelectBoxComponent implements OnInit {
  // @Input() options = [];
  // @Input() control: FormControl;
  // @Input() placeholder = '';
  // @Input() multiple = false;

  // selected = [] as any;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.selectedItemChanged();
  }

  
}

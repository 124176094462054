import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export type MediaFileList = {
  files: Media[];
}

export type Media = {
  "bookingBaseType": number,
  fileId: string;
  fileName: string;
}
// .service.ts files contain methods which interact with backend servers for maintaining data
@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private readonly httpClient: HttpClient) { }


  getUploadDocument(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/booking-document/booking/list', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }

  updateNewDocument(form: any): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/booking-document/booking/update', form, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }

  uploadDocument(media: File[], manualBookingId: string, bookingId: string, bookingBaseType: string): Observable<Media> {
    const formData = new FormData();
    console.log(media);
    for (let m of media) {
      console.log(m);
      formData.append('media', m);
      formData.append('bookingBaseType', bookingBaseType);
      formData.append('manualBookingId', manualBookingId);
      formData.append('bookingId', bookingId);
    }

    return this.httpClient.post<Media>(environment.apiUrl + '/file/booking/upload', formData, { observe: 'body', withCredentials: true });
  }

  downloadMedia(payload): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/file/booking/download', payload, { observe: 'response', responseType: 'blob', withCredentials: true }).pipe(map(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

      if (contentDisposition) {
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          return res.clone({
            headers: res.headers.append('fileName', matches[1].replace(/['"]/g, ''))
          });
        }
      }
      return res;
    }));
  }

  // HttpClient for getting booking list
  getBookingList(params: any): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/booking/list', {
      params: params,
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  // HttpClient for getting booking policy list
  getBookingPolicyList() {
    return this.httpClient.get(environment.apiUrl + '/manual-booking-policy/list', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  getHotelArea(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/location/area/list', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }


  getHotelSubDistrict(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/location/sub-district/list', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  getCountryList(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/location/country/list', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }))
  }


  getBedTypeList(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/direct-bed-types', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }))
  }



  // HttpClient for adding booking
  addBooking(payload: any
  //   {
  //   confirmationNumber: string,
  //   propertyName: string,
  //   areaId: string,
  //   subDistrictId: string,
  //   propertyAddress: string,
  //   propertyContactNumberCountryId: string,
  //   propertyContactNumber: string,
  //   roomTypeName: string,
  //   numOfRooms: number,
  //   bedTypeName: string,
  //   numOfBeds: number,
  //   propertyNameChi: string,
  //   propertyAddressChi: string,
  //   isWithBreakfast: number,
  //   packageName: string,
  //   stayType: number,

  //   checkInDate: string,
  //   checkOutDate: string,
  //   numOfNights: number,
  //   checkInDateTime: string,
  //   checkOutDateTime: string,
  //   numOfHours: number,
  //   adultCount: number,
  //   childCount: number,
  //   isNonSmoking: number,
  //   otherInclusion: string,
  //   packageRemarks: string,

  //   totalPrice: number,

  //   // Total Package Price
  //   baseTotalPrice: number,
  //   basePrice: number,
  //   markupPercentage: number,
  //   discount: number,

  //   // Price (on night basis)
  //   nightAverageTotalPrice: number,
  //   nightAverageBasePrice: number,
  //   nightAverageMarkupPercentage: number,
  //   nightAverageDiscount: number,

  //   // Price (on night basis)
  //   hourAverageTotalPrice: number,
  //   hourAverageBasePrice: number,
  //   hourAverageMarkupPercentage: number,
  //   hourAverageDiscount: number,

  //   // Supplement 1
  //   supplementTotalPrice: number,
  //   supplementName: string,
  //   supplementBasePrice: number,
  //   supplementMarkupPercentage: number,
  //   supplementDiscount: number,

  //   // Supplement 2
  //   supplementSecondTotalPrice: number,
  //   supplementSecondName: string,
  //   supplementSecondBasePrice: number,
  //   supplementSecondMarkupPercentage: number,
  //   supplementSecondDiscount: number,

  //   // Night Average Occupancy
  //   nightAverageOccupancyTotalPrice: number,
  //   nightAverageOccupancyMarkupPercentage: number,
  //   nightAverageOccupancyDiscount: number,
  //   nightAverageAdultPrice: number,
  //   nightAverageChildPrice: number,

  //   // changeAmount: number,
  //   // refundAmount: number,
  //   paymentType: number,
  //   paymentLink: string,
  //   paymentBeforeDate: string,
  //   contactPersonPersonType: number,
  //   contactPersonFirstName: string,
  //   contactPersonLastName: string,
  //   contactPersonEmail: string,
  //   contactPersonContactNumberCountryId: string,
  //   contactPersonContactNumber: string,
  //   arrivalDetails: string,
  //   arrivalTime: string,

  //   manualBookingPolicyId: string,
  //   bookingPolicyRemarks: string,
  //   supplierName: string,
  //   supplierEmail: string,
  //   reservationEmail: string,
  //   salesEmail: string,
  //   supplierCost: any,
  //   supplementSupplierCost: number,
  //   supplementSecondSupplierCost: number,
  //   supplierDiscount: number,

  //   guests: any[],
  //   coupon: string,
  //   specialRequest: string

  //   adminStatus: number,
  // }
  ): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }

  // HttpClient for deleting booking
  deleteBooking(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking/delete', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  // HttpClient for getting booking details
  getBookingDetail(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking/view', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  // HttpClient for editting booking
  editBooking(payload: any
  //   {
  //   bookingBaseType: number,
  //   manualBookingId: string,
  //   bookingId: string,

  //   confirmationNumber: string,
  //   propertyName: string,
  //   areaId: string,
  //   subDistrictId: string,
  //   propertyAddress: string,
  //   propertyContactNumberCountryId: string,
  //   propertyContactNumber: string,
  //   roomTypeName: string,
  //   numOfRooms: number,
  //   bedTypeName: string,
  //   directBedTypeId: string,
  //   numOfBeds: number,
  //   propertyNameChi: string,
  //   propertyAddressChi: string,
  //   isWithBreakfast: number,
  //   packageName: string,
  //   stayType: number,

  //   checkInDate: string,
  //   checkOutDate: string,
  //   numOfNights: number,
  //   checkInDateTime: string,
  //   checkOutDateTime: string,
  //   numOfHours: number,
  //   adultCount: number,
  //   childCount: number,
  //   isNonSmoking: number,
  //   otherInclusion: string,
  //   packageRemarks: string,

  //   totalPrice: number,

  //   // Total Package Price
  //   baseTotalPrice: number,
  //   basePrice: number,
  //   markupPercentage: number,
  //   discount: number,

  //   // Price (on night basis)
  //   nightAverageTotalPrice: number,
  //   nightAverageBasePrice: number,
  //   nightAverageMarkupPercentage: number,
  //   nightAverageDiscount: number,

  //   // Price (on night basis)
  //   hourAverageTotalPrice: number,
  //   hourAverageBasePrice: number,
  //   hourAverageMarkupPercentage: number,
  //   hourAverageDiscount: number,

  //   // Supplement 1
  //   supplementTotalPrice: number,
  //   supplementName: string,
  //   supplementBasePrice: number,
  //   supplementMarkupPercentage: number,
  //   supplementDiscount: number,

  //   // Supplement 2
  //   supplementSecondTotalPrice: number,
  //   supplementSecondName: string,
  //   supplementSecondBasePrice: number,
  //   supplementSecondMarkupPercentage: number,
  //   supplementSecondDiscount: number,

  //   // Night Average Occupancy
  //   nightAverageOccupancyTotalPrice: number,
  //   nightAverageOccupancyMarkupPercentage: number,
  //   nightAverageOccupancyDiscount: number,
  //   nightAverageAdultPrice: number,
  //   nightAverageChildPrice: number,

  //   // changeAmount: number,
  //   // refundAmount: number,
  //   paymentType: number,
  //   paymentLink: string,
  //   paymentBeforeDate: string,
  //   contactPersonPersonType: number,
  //   contactPersonFirstName: string,
  //   contactPersonLastName: string,
  //   contactPersonEmail: string,
  //   contactPersonContactNumberCountryId: string,
  //   contactPersonContactNumber: string,
  //   arrivalDetails: string,
  //   arrivalTime: string,

  //   manualBookingPolicyId: string,
  //   bookingPolicyRemarks: string,
  //   supplierName: string,
  //   supplierEmail: string,
  //   reservationEmail: string,
  //   salesEmail: string,
  //   supplierCost: any,
  //   supplementSupplierCost: number,
  //   supplementSecondSupplierCost: number,
  //   supplierDiscount: number,

  //   guests: any[],
  //   coupon: string,
  //   specialRequest: string,
  //   adminStatus: number,
  //   depositAmount: number,
  //   amountPaid: number,
  //   rtxAdditionalPolicies: string,
  //   rtxAdditionalCheckInPolicies: string,
  //   rtxCheckInInStructions: string,
  // }
  ): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking/edit', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }


  // Preview APIs
  previewSupplierCancellation(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-cancellation/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerCancellation(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-cancellation/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewSupplierReservationOrder(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-reservation-order/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewSupplierReservationOrderAmendment(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-reservation-order-amendment/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerInvoice(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-invoice/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerInvoiceAmendment(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-invoice-amendment/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerConfirmation(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-confirmation/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerConfirmationAmendment(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-confirmation-amendment/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewSupplierRoomStatusCheck(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-room-status-check/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerAcknowledgement(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-acknowledgement/preview', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  // Sending APIs
  sendSupplierCancellation(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-cancellation/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerCancellation(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-cancellation/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendSupplierReservationOrder(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-reservation-order/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendSupplierReservationOrderAmendment(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-reservation-order-amendment/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerInvoice(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-invoice/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerInvoiceAmendment(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-invoice-amendment/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerConfirmation(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-confirmation/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerConfirmationAmendment(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-confirmation-amendment/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendSupplierRoomStatusCheck(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/supplier-room-status-check/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerAcknowledgement(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/booking-email/customer-acknowledgement/send', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }


  downloadPDF(payload: {
    bookingBaseType: number,
    manualBookingId: string,
    bookingId: string,
    lang: string,
    apiName: string
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/booking-email/' + payload.apiName + '/download-pdf', payload, { observe: 'response', responseType: 'blob', withCredentials: true }).pipe(map(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

      if (contentDisposition) {
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          return res.clone({
            headers: res.headers.append('fileName', matches[1].replace(/['"]/g, ''))
          });
        }
      }
      return res;
    }));
  }

  cancelMTOrder(form: any): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/mt/cancel-order', form, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  cancelRTXOrder(form: any): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/rakuten/cancel-order', form, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  capturePayment(bookingId: any, form:any): Observable<any> {
    return this.httpClient.post(environment.apiUrl + `/booking/capture/${bookingId}`, form, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

}


import { Component, OnInit, Input } from '@angular/core';
import { LoginRecord } from '../../models/component/login-record.model';

@Component({
  selector: 'app-last-login',
  templateUrl: './last-login.component.html',
  styleUrls: ['./last-login.component.scss']
})
export class LastLoginComponent implements OnInit {

@Input()  lastLoginRecord:LoginRecord;


  constructor() { 
    
  }

  ngOnInit(): void {
  }

}

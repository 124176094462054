import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';
import * as moment from 'moment';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit {
  @Input() isRequired = false;
  @Input() control: FormControl;
  @Input() placeholder = '';
  @Input() isReadOnly = true;
  @Input() isDisabled = false;
  @Input() prefill = '';
  @Input() changeDate: Function;
  @Input() timePicker = false

  isClearable = false;

  constructor() { }

  ngOnInit(): void {
    this.control.valueChanges.pipe(startWith(this.control.value)).subscribe(res => {
      this.isClearable = moment(res).isValid();
    });
  }

  clearDate(): void {
    this.control.reset();
  }

}

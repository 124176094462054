<mat-form-field *ngIf="!timePicker" appearance="fill">
    <input matInput [matDatepicker]="picker" [formControl]="control" [readonly]="isReadOnly" [placeholder]="placeholder"
        [value]="prefill" (selectedChange)="changeDate">
    <div matSuffix style="display: flex; align-items: center;">
        <!-- <div [ngStyle]="{'visibility': formControl?.value ? 'visible': 'hidden'}"(click)="clearDate()" style="color:#808080">x</div> -->
        <ng-container >
            <mat-datepicker-toggle [for]="picker" [disabled]="isDisabled">
            </mat-datepicker-toggle>
        </ng-container>

    </div>

    <mat-datepicker #picker panelClass="custom-calendar"></mat-datepicker>
</mat-form-field>



<mat-form-field *ngIf="timePicker" appearance="fill">
    <input matInput [ngxMatDatetimePicker]="picker" [placeholder]="placeholder" [value]="prefill"
        (selectedChange)="changeDate" [formControl]="control">
    <mat-datepicker-toggle matSuffix [for]="picker">
    </mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [stepHour]="1" [stepMinute]="5"
        [stepSecond]="1" [touchUi]="false">
    </ngx-mat-datetime-picker>

</mat-form-field>
<mat-form-field appearance="fill" >
    <mat-select [formControl]="control" [disabled]="control.disabled" [placeholder]="placeholder" (openedChange)="openedChange($event)">
        <mat-select-trigger>           
            <ng-container *ngTemplateOutlet="item;context: {option: selected}"></ng-container>
        </mat-select-trigger>
        <div style="max-height: 256px;overflow-y: auto;display: flex;flex-direction: column;align-items: stretch;">
            <mat-option style="flex:0 0 auto;background-color: #F5F5F5;" [disabled]="true">
                <input type="text" #textSearchInput="matInput" matInput   [formControl]="textSearch" autocomplete="off" (keydown)="$event.stopPropagation()">
             </mat-option>
            <div style="flex:0 1 100%; overflow-y: auto;">
                <ng-container *ngFor="let option of options">
                    <mat-option *ngIf="option?.viewValue.trim().toLowerCase().includes(textSearch.value.trim().toLowerCase())" [value]="option.value">
                        <ng-container *ngTemplateOutlet="item;context: {option: [option]}"></ng-container>
                    </mat-option>
                </ng-container>
            </div>
        </div>
    </mat-select>
</mat-form-field>
<ng-template #item let-option="option">
    <div class="option-container"> 
        <ng-container *ngFor="let o of option; let isLast = last">
            <span class="item-text" >                
                <ng-container>{{o?.viewValue}}</ng-container>
                <ng-container *ngIf="!isLast">&#44;&nbsp;</ng-container>
            </span>
        </ng-container>       
    </div>
</ng-template>
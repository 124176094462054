import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogTempleteComponent } from './alert-dialog-templete/alert-dialog-templete.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatInputModule } from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { EnterPasswordComponent } from './components/enter-password/enter-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import {PortalModule} from '@angular/cdk/portal';
import { AddImageDescriptionComponent } from './add-image-description/add-image-description.component';
import { FormsModule } from '@angular/forms';
import { AddFileNameDescriptionComponent } from './add-file-name-description/add-file-name-description.component';

@NgModule({
  declarations: [AlertDialogTempleteComponent, ResetPasswordComponent, ChangePasswordComponent, EnterPasswordComponent, NewPasswordComponent, AddImageDescriptionComponent, AddFileNameDescriptionComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    PortalModule,
    FormsModule
  ],
  exports:[
    AlertDialogTempleteComponent,ChangePasswordComponent,ResetPasswordComponent, AddImageDescriptionComponent
  ]
})
export class DialogTemplateModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export type MediaFileList = {
  files: Media[];
}

export type Media = {
  fileId: string;
  fileName: string;
}
@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor(private readonly httpClient: HttpClient) { }


  getUploadDocument(payload: {
    activityBookingId: string,
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/activity-booking-document/booking/list', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }

  updateNewDocument(form:any): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/activity-booking-document/booking/update', form, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }

  uploadDocument(media: File[], manualBookingId:string,  bookingId:string, bookingBaseType: string): Observable<Media> {
    const formData = new FormData();
    for (let m of media) {
      formData.append('media', m);
      formData.append('bookingBaseType',bookingBaseType);
      formData.append('manualBookingId',manualBookingId);
      formData.append('bookingId',bookingId);
    }

    return this.httpClient.post<Media>(environment.apiUrl + '/file/activity-booking/upload', formData, {observe: 'body', withCredentials: true});
  }

  downloadMedia(payload): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/file/activity-booking/download', payload, {observe: 'response', responseType: 'blob', withCredentials: true}).pipe(map(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
       
      if (contentDisposition) {
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          return res.clone({
            headers: res.headers.append('fileName', matches[1].replace(/['"]/g, ''))   
          });
        }        
      }
      return res;
    }));
  }

  // HttpClient for getting tour list
  getTourList(params: any): Observable <any> {
    return this.httpClient.get(environment.apiUrl + '/activity-booking/list', {
      params: params,
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  // HttpClient for getting booking policy list
  getTourPolicyList(){
    return this.httpClient.get(environment.apiUrl + '/manual-activity-booking-policy/list', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      // console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  getCountryList() {
    return this.httpClient.get(environment.apiUrl + '/location/country/list', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }))
  }

  // HttpClient for getting 
  addTour(payload: {
    confirmationNumber: string,
    activityName: string,
    activityCode: string,
    activityNameChi: string,
    activityCodeChi: string,
    activityDescription: string,
    activityDurationType: number,
    startDate: string,
    numOfDays: number,
    pickUpTime: string,
    pickUpLocation: string,
    pickUpLocationLink: string,
    departureTime: string,
    departureDetails: string,
    adultCount: string,
    childCount: string,
    guests: {
      activityBookingGuestId: string,
      guestPersonType: number,
      guestFirstName: string,
      guestLastName: string,
      guestPassport: string,
    }[],
    arrivalFlightDetails: string,
    arrivalFlightDepartureTime: string,
    arrivalFlightArrivalTime: string,
    departureFlightDetails: string,
    departureFlightDepartureTime: string,
    departureFlightArrivalTime: string,
    dailyDetails: {
      activityBookingDailyDetailId: string,
      activityDailyDescription: number,
    }[],
    totalPrice: number,
    totalPricePercentage: number,
    adultPricePercentage: number,
    childPricePercentage: number,
    markupPercentage: number,
    discountPercentage: number,

    totalPriceAbsolute: number,
    adultPriceAbsolute: number,
    childPriceAbsolute: number,
    markupAbsolute: number,
    discountAbsolute: number,

    // changeAmount: number,
    // refundAmount: number,

    paymentType: number,
    paymentLink: string,
    paymentBeforeDate: string,
    contactPersonPersonType: string,
    contactPersonFirstName: string,
    contactPersonLastName: string,
    contactPersonEmail: string,
    contactPersonContactNumber: string,
    manualActivityBookingPolicyId: string,
    bookingPolicyRemarks: string,
    supplierName: string,
    supplierEmail: string,
    supplierEmergencyContactPerson: string,
    supplierEmergencyContactNumber: string,
    supplierCost: number,
    adminStatus: number,
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/activity-booking', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }

  // HttpClient for deleting tour
  deleteTour(payload: {
    activityBookingId: string
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/activity-booking/delete', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  // HttpClient for getting details of tour in view page
  getTourDetail(payload: {
    activityBookingId: string,
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/activity-booking/view', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }

  // HttpClient for editting tour
  editTour(payload: {
    activityBookingId: string,
    confirmationNumber: string,
    activityName: string,
    activityCode: string,
    activityNameChi: string,
    activityCodeChi: string,
    activityDescription: string,
    activityDurationType: number,
    startDate: string,
    numOfDays: number,
    pickUpTime: string,
    pickUpLocation: string,
    pickUpLocationLink: string,
    departureTime: string,
    departureDetails: string,
    adultCount: string,
    childCount: string,
    guests: {
      activityBookingGuestId: string,
      guestPersonType: number,
      guestFirstName: string,
      guestLastName: string,
      guestPassport: string,
    }[],
    arrivalFlightDetails: string,
    arrivalFlightDepartureTime: string,
    arrivalFlightArrivalTime: string,
    departureFlightDetails: string,
    departureFlightDepartureTime: string,
    departureFlightArrivalTime: string,
    dailyDetails: {
      activityBookingDailyDetailId: string,
      activityDailyDescription: number,
    }[],
    totalPrice: number,
    totalPricePercentage: number,
    adultPricePercentage: number,
    childPricePercentage: number,
    markupPercentage: number,
    discountPercentage: number,

    totalPriceAbsolute: number,
    adultPriceAbsolute: number,
    childPriceAbsolute: number,
    markupAbsolute: number,
    discountAbsolute: number,

    // changeAmount: number,
    // refundAmount: number,

    paymentType: number,
    paymentLink: string,
    paymentBeforeDate: string,
    contactPersonPersonType: string,
    contactPersonFirstName: string,
    contactPersonLastName: string,
    contactPersonEmail: string,
    contactPersonContactNumber: string,
    manualActivityBookingPolicyId: string,
    bookingPolicyRemarks: string,
    supplierName: string,
    supplierEmail: string,
    supplierEmergencyContactPerson: string,
    supplierEmergencyContactNumber: string,
    supplierCost: number,
    adminStatus: number,
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + '/activity-booking/edit', payload, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }

  // Preview APIs
  previewSupplierCancellation(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/supplier-cancellation/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerCancellation(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-cancellation/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewSupplierReservationOrder(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/supplier-reservation-order/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewSupplierReservationOrderAmendment(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/supplier-reservation-order-amendment/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerInvoice(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-invoice/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerInvoiceAmendment(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-invoice-amendment/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerConfirmation(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-confirmation/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  previewCustomerConfirmationAmendment(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-confirmation-amendment/preview', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  // Sending APIs
  sendSupplierCancellation(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-cancellation/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerCancellation(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-cancellation/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendSupplierReservationOrder(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/supplier-reservation-order/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendSupplierReservationOrderAmendment(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/supplier-reservation-order-amendment/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerInvoice(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-invoice/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerInvoiceAmendment(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-invoice-amendment/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerConfirmation(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-confirmation/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }

  sendCustomerConfirmationAmendment(payload: {
    activityBookingId: string,
    lang: string
  }): Observable<any> {

    return this.httpClient.post(environment.apiUrl + '/activity-booking-email/customer-confirmation-amendment/send', payload,  {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res);
      return res.body;
    }, error => {
      return error;
    }))
  }
}

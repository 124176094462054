<mat-form-field appearance="fill">
    <mat-label>{{label}}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input id="startDate" matStartDate [formControl]="start" placeholder="From">
      <input id="endDate" matEndDate [formControl]="end" placeholder="To">
    </mat-date-range-input>

    <div matSuffix style="display: flex; align-items: center;">
      <ng-container *ngIf="!isClearable; else clearButton">
        <mat-datepicker-toggle #buttonArea matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <!-- <button (click)="buttonArea.click()"></button> -->
      </ng-container>

      <ng-template #clearButton>
        <ng-container *ngIf="!hideClearButton">
          <button  disableRipple="true" mat-icon-button (click)="clearDateRange()">
            <mat-icon class="clear-btn">clear</mat-icon>
          </button>
        </ng-container>
      </ng-template>
    </div>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
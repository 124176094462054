import { Component, ViewChildren, QueryList, ElementRef, AfterViewInit, AfterViewChecked, ViewEncapsulation, OnInit } from '@angular/core';
import { ConditionalExpr } from '@angular/compiler';
import { isNumber } from 'util';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { startWith, first } from 'rxjs/operators';
import { interval } from 'rxjs';
import * as moment from 'moment';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
 
  title = 'myfamigo';
  otp = new FormArray([
    new FormControl(''),
    new FormControl(''),
    new FormControl(''),
    new FormControl(''),
    new FormControl(''),
    new FormControl(''),
  ]);
  label = new FormControl('', [Validators.required]);
  search = new FormControl('', [Validators.required]);
  text = new FormControl('', [Validators.required]);
  date = new FormControl('2020-05-16T00:00:00Z', [Validators.required]);
 

  flexBox=false;
  // options: any[] = [
  //   {value: 'steak-0', viewValue: 'Steak', color: 'red'},
  //   {value: 'pizza-1', viewValue: 'Pizza', color: 'green'},
  //   {value: 'tacos-2', viewValue: 'Tacos', color: 'blue'}
  // ];
  options = Array.from({length: 1000}, (x, i) => {
    const ran = Math.random() * 100;
    return {
      id: ran,
      value: ran,
      viewValue: ran,
      color: Math.floor(ran) % 3 === 1 ? 'red' : Math.floor(ran) % 3 === 2 ? 'blue' : 'green'
    };
  });

  currentUrl = '';
  constructor(private location: Location) {
    this.currentUrl = this.location.path();
  }
  ngOnInit(): void {
    this.label.valueChanges.subscribe(r => {
      console.log(r);
    });
    this.date.valueChanges.subscribe((r: moment.Moment) => {
      console.log(r);
    });

    this.location.onUrlChange((url) => {
      this.currentUrl = url;
      console.log(url);
    });
  }
  checkOTP(otp: string) {
    console.log(otp);
    
  }

  checkSelect(item: string) {
    console.log(item);
    
  }

  temp(e) {
    console.log(e);
    
  }

  flexTest(){
    this.flexBox=!this.flexBox;
  }

  getSelectedIndex(index:string){
    
  }
}

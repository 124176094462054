import { TextBoxComponent } from '../base/text-box/text-box.component';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-integer-text-box',
  templateUrl: './integer-text-box.component.html',
  styleUrls: ['./integer-text-box.component.scss']
})
export class IntegerTextBoxComponent extends TextBoxComponent implements OnInit {

  @Input() step: number = 1;

  constructor() { super(); }

  ngOnInit(): void {
  }

  add() {
    if (!Number.isNaN(Number(this.control.value))) {
      this.control.patchValue(Math.floor(this.control.value) + this.step);
    }
    else
      this.control.patchValue(0);
  }
  reduce() {
    if (!Number.isNaN(Number(this.control.value)) && this.control.value > 0) {
      this.control.patchValue(Math.ceil(this.control.value) - this.step);
    }
    else
      this.control.patchValue(0);
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements OnInit {
  @Input() isRequired = false;
  @Input() control: FormControl;
  @Input() isDisabled = false;
  @Input() placeholder = '';
  @Input() prefill = '';

  constructor() { 
    console.log(this.control);
  }

  ngOnInit(): void {
    this.control.patchValue(this.prefill)
  }
  
}
<div class="circle-container">
    <mat-progress-spinner strokeWidth="10" diameter="171" class="circle-background" mode="determinate" 
        [value]="100">
    </mat-progress-spinner>
    <mat-progress-spinner strokeWidth="5" [ngClass]="spinnerColorClass" class="circle-progress" diameter="166"  mode="determinate"
        [value]="score">
    </mat-progress-spinner>
    <div class="circle-score" [ngClass]="textColorClass">
        <span class="score-value">{{score}}</span>
        <span class="score-percent">%</span>

    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import {Moment} from 'moment';
@Pipe({name: 'timeZone'})
export class TimeZonePipe implements PipeTransform {
  transform(value:string|Moment,locale:string = "Hongkong",format:string="hh:mm a | DD MMM YYYY"): string {
    return moment(value,moment.ISO_8601).isValid()&&momentTimeZone.tz.zone(locale)?
     (momentTimeZone(moment(value)).tz(locale)).format(format)
    :
     " - | - ";
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoUploadBoxComponent } from './photo-upload-box.component';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [PhotoUploadBoxComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule
  ],
  exports: [PhotoUploadBoxComponent]
})
export class PhotoUploadBoxModule { }

import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Form} from '@angular/forms';
import { takeUntil, startWith } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent implements OnInit {

  @Input() label = '';
  @Input() start: FormControl;
  @Input() end: FormControl;
  @Input() hideClearButton = false;
  
  isClearable = false;

  constructor() { }

  ngOnInit(): void {
    this.end.valueChanges.pipe(startWith(this.end.value)).subscribe(res => {
      this.isClearable = moment(res).isValid();
    });
  }

  clearDateRange(): void {
    this.end.reset();
    this.start.reset();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkInfoComponent } from './work-info.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextBoxModule } from '../base/text-box/text-box.module';
import { SelectBoxModule } from '../base/select-box/select-box.module';
import { LabelSelectModule } from '../label-select/label-select.module';
import { DatePickerModule } from '../date-picker/date-picker.module';



@NgModule({
  declarations: [WorkInfoComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    TextBoxModule,
    SelectBoxModule,
    DatePickerModule,
    LabelSelectModule,
    TranslateModule
  ],
  exports: [WorkInfoComponent]
})
export class WorkInfoModule { }

import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit,AfterViewInit,OnDestroy {


  passwordForm:FormGroup= this.fb.group({
    magic:[''],
    confirmMagic:[''],

  });

  destroy$=new Subject();
  isError=false;
  isNotMatch=true;
  hide = true;
  constructor(private fb : FormBuilder) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngAfterViewInit() {
    
  }

  ngOnInit(): void {
    // this.passwordForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x=>{
    //   this.isNotMatch=!this.checkFormInput(x);
    // });
    combineLatest(this.magic.valueChanges,
      this.confirmMagic.valueChanges
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(([magic,confirmMagic])=>{
      this.isNotMatch=!this.checkFormInput({magic,confirmMagic});
    });
  }

  checkFormInput(value){
    return !!value['magic']&&!!value['confirmMagic']
  }

  checkNewPassword(value){
    return value['magic']==value['confirmMagic'];
  }

  resetPassword(){
    if(!this.checkNewPassword({magic:this.magic.value,confirmMagic:this.confirmMagic.value}))
    {
      this.resetForm();
      this.isError=true;
    }
    else{
      this.resetForm();
      this.isError=true;
    }
  }

  resetForm(){
    this.passwordForm.reset();
  }


get magic(){
  return this.passwordForm.get('magic'); 
}
get confirmMagic(){
  return this.passwordForm.get('confirmMagic'); 
}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompletionScoreComponent } from './completion-score.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [CompletionScoreComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CompletionScoreComponent
  ]
})
export class CompletionScoreModule { }

import { ValueConverter } from '@angular/compiler/src/render3/view/template';

export abstract class AdminRole {

    constructor(private _roleValue: number, private _translateKey: string, private _colorCode: string) {
        
    
    }

    get roleValue() { return this._roleValue; }
    get translateKey() {return this._translateKey; }
    get colorCode() { return this._colorCode; }
}
<mat-form-field appearance="fill" >
    <mat-select [formControl]="control" [disabled]="isDisabled" [placeholder]="placeholder" [multiple]="multiple" [value]="prefill">
        <mat-select-trigger>           
            <ng-container *ngTemplateOutlet="item;context: {option: selected}"></ng-container>
        </mat-select-trigger>
        <mat-option *ngFor="let option of options" [value]="option.value">
            <ng-container *ngTemplateOutlet="item;context: {option: [option]}"></ng-container>
        </mat-option>
    </mat-select>
</mat-form-field>

<ng-template #item let-option="option">
    <div class="option-container"> 
        <ng-container *ngFor="let o of option; let isLast = last">
            <ng-container *ngIf="o.icon != null"><img class="icon-image" [src]="o.icon"></ng-container>
            <span class="item-text" >
                <ng-container>{{o?.viewValue}}</ng-container>
                <ng-container *ngIf="!isLast">&#44;&nbsp;</ng-container>
            </span>
        </ng-container>       
    </div>
</ng-template>
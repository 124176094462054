import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from 'src/app/shared/components/loading-dialog/loading-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  dialogId = '';
  dialogCounter = 0;
  constructor(private dialog: MatDialog) {
   
  }

  showLoading(): void {    
    if (this.dialogCounter++ === 0) {
      this.dialogId = (this.dialog.open(LoadingDialogComponent, {
        width: '350px',
        disableClose: true,
        autoFocus: false,
        data: {}
      }).id);     
    }
  }

  closeLoading(): void {    
    if (this.dialogCounter-- === 1) {
      this.dialog.getDialogById(this.dialogId)?.close();
    }    
  }
}

<div class="add-file-name-description-container">

    <form autocomplete="off" [formGroup]="form">
        <div class="add-file-name-description-title-container"
            style="display:flex;flex-direction: row;justify-items: stretch;">
            <span style="flex:0 1 100%; word-break:break-all;" class="add-file-name-description-title">
                Please enter the name and description
            </span>
            <span class="cursor--pointer" (click)="onNoClick()">
                <img src="../../../../../assets/images/close-button.svg" />
            </span>
        </div>

        <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Name*</mat-label>
            <input matInput formControlName="bookingDocumentName">
        </mat-form-field>

        <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput formControlName="bookingDocumentDescription">
        </mat-form-field>

        <div style="display: grid; grid-template-columns: 1fr auto auto; gap: 10px; align-items: center;">

            <mat-form-field>
                <mat-label>Files*</mat-label>
                <input matInput formControlName="fileName" readonly>
            </mat-form-field>
            <div>
                <button mat-stroked-button color="primary" type="button" (click)="removeFile()">Remove</button>
            </div>
            <div>
                <button mat-flat-button color="primary" type="button" (click)="upload.click()">Upload</button>
            </div>
        </div>


        <div mat-dialog-actions style="display:flex;flex-direction: row;justify-content: space-between;">
            <button class="pair-button button--cancel mat-elevation-z0" mat-button (click)="onNoClick()"
                mat-raised-button>Cancel</button>
            <button class="pair-button button--confirm mat-elevation-z0" mat-button (click)="onConfirmClick()"
                mat-raised-button>Ok</button>
        </div>

        <input type="file" #upload style="display: none">  
    </form>
</div>
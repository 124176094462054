<div style="display:flex;flex-direction: column;align-items: stretch;">
    <div class="enter-password-dialog-title-container">
        <span class="enter-password-dialog-title">
            You are able to reset the user's password now.
        </span>
    </div>
    <div [ngClass]="{'visibility--hidden':!isError}" class="dialog-error-message-container">
        <span class="dialog-error-message">
            Your entered password is incorrect. Please try again.
        </span>
    </div>
        <form [formGroup]="passwordForm" style="display:flex;flex-direction: column;align-items: stretch;">
            <mat-form-field [hideRequiredMarker]="true"  class="form-field"  style="flex:1 1" floatLabel="always">
                <mat-label class="dialog-form--label">New password</mat-label>
                <input class="form-field-input" matInput formControlName='magic' [type]="hide ? 'password' : 'text'" autocomplete="off" >
                <button class="eyes-icon--button" type="button" mat-icon-button matSuffix (click)="hide = !hide">
                    <!-- <mat-icon class="text-color--dark-grey" [ngClass]="{'material-icons-outlined':hide3}">visibility</mat-icon> -->
                    <div [ngClass]="{'eyes-icon-view-no':!!hide,'eyes-icon-view':!hide}"></div>
                </button>
            </mat-form-field>
            <mat-form-field [hideRequiredMarker]="true" class="form-field" style="flex:1 1" floatLabel="always">
                <mat-label class="dialog-form--label">Confirm password</mat-label>
                <input class="form-field-input" matInput formControlName='confirmMagic' [type]="hide ? 'password' : 'text'" autocomplete="off" >
                <button class="eyes-icon--button" type="button" mat-icon-button matSuffix (click)="hide = !hide">
                    <!-- <mat-icon class="text-color--dark-grey" [ngClass]="{'material-icons-outlined':hide3}">visibility</mat-icon> -->
                    <div [ngClass]="{'eyes-icon-view-no':!!hide,'eyes-icon-view':!hide}"></div>
                </button>
            </mat-form-field>



        </form>
</div>
<div class="alert-dialog-content" style="display:flex;flex-direction: row-reverse;justify-content: space-between;">
    <button class="pair-button mat-elevation-z0" [disabled]="!!isNotMatch" type="button" [ngClass]="{'button--disabled':!!isNotMatch,'button--save-changes':!isNotMatch}"
        (click)="resetPassword()" [disableRipple]="true" mat-raised-button>Save Changes</button>
</div>
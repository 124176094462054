import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-image-description',
  templateUrl: './add-image-description.component.html',
  styleUrls: ['./add-image-description.component.scss']
})
export class AddImageDescriptionComponent implements OnInit {

  description: string;

  constructor(public dialogRef: MatDialogRef<AddImageDescriptionComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  onConfirmClick(): void {
    this.dialogRef.close(this.description);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}

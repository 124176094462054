import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent implements OnInit, OnChanges {
  @Input() options = [];
  @Input() control: FormControl;
  @Input() placeholder = '';
  @Input() multiple = false;
  @Input() isDisabled = false;
  @Input() prefill = null;
  @Input() changeSelection: Function;
  
  selected = [] as any;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.prefill != null) {
      this.control.patchValue(this.prefill)
    }

    this.selectedItemChanged();
  }

  ngOnInit(): void {
    if (this.prefill != null) {
      this.control.patchValue(this.prefill)
    }

    this.selectedItemChanged();
  }

  selectedItemChanged() {
    this.control?.valueChanges.pipe(startWith(this.control.value)).subscribe((r) => {
      if (this.multiple) {
        this.selected = this.options.filter(o => r?.includes(o.value));
      }
      else {
        this.selected = this.options.filter(o => o.value === r);
      }
    });
  }
}

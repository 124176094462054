import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input() tabs;
  @Input() badgeCount;
  @Input() menuItems = [];
  @Input() username;
  @Input() role;
  @Output() tabSelectedChange = new EventEmitter<number>();
  @Output() selectedItemIndex = new EventEmitter<number>();
  constructor() {
  }

  ngOnInit(): void {
  }

  tabChange(event) {
    console.log(event);
    
    this.tabSelectedChange.emit(event); 
  }
}

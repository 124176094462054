import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap, debounce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  // HttpClient for getting user info
  getUserInfo(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/user/info', {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      console.log(res.body)
      return res.body;
    }, error => {
      return error;
    }));
  }


  changePassword(password: string): Observable<any> {
    let form = {"password": password}
    return this.httpClient.put(environment.apiUrl + '/user/password', form, {
      observe: 'response',
      withCredentials: true
    }).pipe(map(res => {
      return res.body;
    }, error => {
      console.log(error)
      return error;
    }));
  }
}

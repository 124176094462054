import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements OnInit {
  @Input() tabs = [];
  @Output() tabSelectedChange = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

}

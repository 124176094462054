<mat-form-field appearance="fill" >
    <mat-select [formControl]="control" [disabled]="control.disabled" [multiple]="multiple">
        <mat-select-trigger>           
            <ng-container *ngTemplateOutlet="item;context: {option: selected}"></ng-container>
        </mat-select-trigger>
        <mat-option *ngFor="let option of options" [value]="option.value">
            <ng-container *ngTemplateOutlet="item;context: {option: [option]}"></ng-container>
        </mat-option>
    </mat-select>
</mat-form-field>

<ng-template #item let-option="option">
    <div class="option-container" >
        <ng-container  >
            <ng-container>
                <svg width="23" height="13">
                    <rect rx="3" ry="3" width="13" height="13" [ngStyle]="{'fill': option[0]?.color || ''}" />
                    </svg>
                <span class="item-text" >
                    <ng-container>{{option[0]?.viewValue}}</ng-container>
                    <ng-container *ngIf="option.length > 1">&#44;</ng-container>  
                </span>
                <span>
                    <ng-container *ngIf="option.length > 1">&hellip;</ng-container>
                </span>
            </ng-container>
                     
        </ng-container>        
    </div>
</ng-template>
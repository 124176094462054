import { Component, Inject, Input, OnInit } from '@angular/core';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogTempleteComponent } from 'src/app/shared/components/dialog-template/alert-dialog-templete/alert-dialog-templete.component';
import { AddImageDescriptionComponent } from '../dialog-template/add-image-description/add-image-description.component';


@Component({
  selector: 'app-photo-upload-box',
  templateUrl: './photo-upload-box.component.html',
  styleUrls: ['./photo-upload-box.component.scss']
})
export class PhotoUploadBoxComponent implements OnInit {

  @Input() title = '';
  @Input() isMultiple = true;

  isInEditMode = false;
  showCloseButton = false;

  placeholder: string | SafeUrl =
    "../../../../assets/images/photo-upload.png";

  images: any[] = [];

  constructor(private sanitizer: DomSanitizer, public dialog: MatDialog) {}


  updateImage(ev) {
    console.log(`Num of selected files: ${ev.target.files.length}`)    

    for (let i = 0; i < ev.target.files.length; i++){

      let image = {
        url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(ev.target.files[i])),
        fileName: ev.target.files[i].name,
        description: ""
      }

      this.addImageDescriptionDialog(image);
    }

    // this.addImageDescriptionDialog();

  }

  removeImage(index) {
    const dialogRef = this.dialog.open(AlertDialogTempleteComponent,{
      data:{
        title:`Delete ${this.images[index].fileName}?`,
        content:'The deletion cannot be reversed.',
        isConfirm:true
      },
      panelClass:"alert-message-dialog",
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      
      if (dialogResult) {
        this.images.splice(index, 1);
      }
      else {
        dialogRef.close();
      }
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    console.log("Enter drag and drop function")
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    console.log(this.images);
  }
  
  ngOnInit(): void {
  }

  addImageDescriptionDialog(image) {
    const dialogRef = this.dialog.open(AddImageDescriptionComponent, {
      width: '500px',
      data: {
        image: image,
        title: `Add description: ${image.fileName}`,
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      
      if (dialogResult) {
        image.description = dialogResult;
        this.images.push(image);
        console.log(this.images);      
      }
      else {
        dialogRef.close();
      }

      
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectBoxComponent } from '../base/select-box/select-box.component';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-filter-search-box',
  templateUrl: './filter-search-box.component.html',
  styleUrls: ['./filter-search-box.component.scss']
})
export class FilterSearchBoxComponent extends SelectBoxComponent implements OnInit {
  @ViewChild('textSearchInput') textSearchInput:MatInput;
  textSearch=new FormControl('');

  constructor() {
    super();
   }

  ngOnInit(): void {
    super.ngOnInit();
  }

  openedChange(e){
    
    if(e){
      this.textSearchInput.focus();
   
    }
    this.textSearch.patchValue('');
  }
}

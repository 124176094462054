import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { BookingService } from 'src/app/core/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TourService } from 'src/app/core/http/tour.service';

@Component({
  selector: 'app-add-file-name-description',
  templateUrl: './add-file-name-description.component.html',
  styleUrls: ['./add-file-name-description.component.scss']
})
export class AddFileNameDescriptionComponent implements OnInit {
  @ViewChild('upload') upload: ElementRef = new ElementRef(null);
  formId = this.fb.group({
    bookingBaseType: [null],
    manualBookingId: [null],
    bookingId: [null],
    bookingDocuments: [null]
  });

  form = this.fb.group({
    bookingDocumentName: [null, [Validators.required]],
    bookingDocumentDescription: [null],
    bookingDocumentType:[null],
    fileId: [null, [Validators.required]],
    fileName: [null],
    bookingDocumentId: [null]
  });

  name: string;
  description: string;
  bookingBaseType: string;
  manualBookingId: string;
  bookingId: string;
  bookingDocuments: Array<any>;
  currentDocuments: {};
  isTour = false



  constructor(public dialogRef: MatDialogRef<AddFileNameDescriptionComponent>,private router: Router,
    private tourService: TourService,private route: ActivatedRoute, private fb: FormBuilder, private bookingService:BookingService, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.bookingBaseType= data.bookingBaseType;
    this.manualBookingId= data.manualBookingId;
    this.bookingId= data.bookingId;
    this.bookingDocuments = data.bookingDocuments;
    this.currentDocuments = data.currentDocuments;
    this.isTour = data.isTour;

    this.formId.patchValue({
      bookingBaseType: this.bookingBaseType,
      manualBookingId: this.manualBookingId,
      bookingId: this.bookingId
    })

      if (this.currentDocuments != null) {

        if (this.currentDocuments['bookingDocumentType'] === 1 || this.currentDocuments['bookingDocumentType'] === 0) {
          // this.form.controls['bookingDocumentDescription'].disable();
          this.form.controls['bookingDocumentName'].disable();
        }
      this.form.patchValue({
        bookingDocumentName: this.currentDocuments['bookingDocumentName'],
        bookingDocumentDescription: this.currentDocuments['bookingDocumentDescription'],
        bookingDocumentType: this.currentDocuments['bookingDocumentType'],
        fileId: this.currentDocuments['fileId'],
        fileName: this.currentDocuments['fileName'],
        bookingDocumentId: this.currentDocuments['bookingDocumentId']
      })
    }
  }

  ngOnInit(): void {
  }

  onConfirmClick(): void {
    if(this.form.valid){


      if (this.currentDocuments != null) {

        let updateItem = this.bookingDocuments.indexOf(this.currentDocuments);

        console.log(updateItem)
        this.bookingDocuments[updateItem].bookingDocumentName = this.form.get('bookingDocumentName').value
        this.bookingDocuments[updateItem].bookingDocumentDescription = this.form.get('bookingDocumentDescription').value
        this.bookingDocuments[updateItem].fileId = this.form.get('fileId').value
        this.bookingDocuments[updateItem].fileName = this.form.get('fileName').value
    
       } else {
          this.bookingDocuments.push(this.form.value);
        }

        this.formId.patchValue({
          bookingDocuments: this.bookingDocuments
        })
    this.dialogRef.close({
      form: this.formId.value
    });
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }


  ngAfterViewInit(): void {
    this.upload.nativeElement.addEventListener('change', (event: any) => {
      if (!event.target.files) {        
        return;
      }
      console.log(event.target.files);

      const servicePath = this.isTour? this.tourService.uploadDocument(event.target.files, this.manualBookingId, this.bookingId, this.bookingBaseType) : this.bookingService.uploadDocument(event.target.files, this.manualBookingId, this.bookingId, this.bookingBaseType)

      servicePath.subscribe(res => {
        this.form.patchValue({
          fileName: res.fileName,
          fileId: res.fileId
        });

        if (this.currentDocuments !=null) {
          this.form.patchValue({
            bookingDocumentType: this.currentDocuments['bookingDocumentType']
          });
        } else {
          this.form.patchValue({
            bookingDocumentType: -1
          });
        }

        this.upload.nativeElement.value = '';     
      })
     

    });
  }

  removeFile(): void{
    this.form.patchValue({
      fileName: null,
      fileId: null,
    });
    this.upload.nativeElement.value = '';    
  }
}

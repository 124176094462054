<div class="add-description-container">

    <div class="add-description-title-container" style="display:flex;flex-direction: row;justify-items: stretch;">
        <span style="flex:0 1 100%; word-break:break-all;" class="add-description-title">
            {{data.title}}
        </span>
        <span class="cursor--pointer" (click)="onNoClick()">
            <img src="../../../../../assets/images/close-button.svg" />
        </span>
    </div>
    <div>
        <img class="card-image" [src]="data.image.url" />
    </div>
    <div mat-dialog-content>
        <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Image Description</mat-label>
            <input matInput [(ngModel)]="description">
        </mat-form-field>
    </div>
    <div mat-dialog-actions style="display:flex;flex-direction: row;justify-content: space-between;">
        <button class="pair-button button--cancel mat-elevation-z0" mat-button (click)="onNoClick()" mat-raised-button>Cancel</button>
        <button class="pair-button button--confirm mat-elevation-z0" mat-button (click)="onConfirmClick()" mat-raised-button>Ok</button>
    </div>
</div>



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WipComponent } from './pages/wip/wip.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashbard/dashbard.module').then(m => m.DashbardModule)
      },
      {
        path: 'properties',
        loadChildren: () => import('./modules/properties/properties.module').then(m => m.PropertiesModule)
      },
      {
        path: 'booking',
        loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule)
      },
      {
        path: 'tour',
        loadChildren: () => import('./modules/tour/tour.module').then(m => m.TourModule)
      },
      {
        path: 'package',
        loadChildren: () => import('./modules/package/package.module').then(m => m.PackageModule)
      },
      {
        path: 'customer',
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: 'promotion',
        loadChildren: () => import('./modules/promotion/promotion.module').then(m => m.PromotionModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account-management/account-management.module').then(m => m.AccountManagementModule)
      },
      {
        path: 'property',
        loadChildren: () => import('./modules/property/property.module').then(m => m.PropertyModule)
      },
    ]
  },
  {
    path: 'wip',
    component: WipComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./modules/forget-password/forget-password.module').then(m => m.ForgetPasswordModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(private readonly httpClient: HttpClient) {}

  getProperties(searchKey?: string): Observable<any> {
    let httpParams = new HttpParams();
    if (searchKey) {
      httpParams = httpParams.append('searchKey', searchKey);
    }
    return this.httpClient
      .get(environment.apiUrl + '/property/list', {
        params: httpParams,
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getProperty(id: String): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/property/' + id, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getPropertyPackages(propertyId: string): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/package/list?property=' + propertyId, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getRoomPackages(roomTypeId: string): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/package/list?roomType=' + roomTypeId, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getPropertyRoomType(propertyId: string): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + `/property/${propertyId}/room-type/list`, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getRoomTypeDetail(roomTypeId: string): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + `/property/room-type/${roomTypeId}`, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getPropertyPhotos(propertyId: string): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/file/image-library/' + propertyId, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  uploadPhotos(propertyId: string, media: File[]): Observable<any> {
    const formData = new FormData();
    console.log(media);
    for (let m of media) {
      console.log(m);
      formData.append('media', m);
    }

    console.log(formData);
    return this.httpClient.post(
      environment.apiUrl + '/file/image-library/' + propertyId,
      formData,
      { observe: 'body', withCredentials: true }
    );
  }

  getLocationSubDistrict(areaId: string): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + `/location/sub-district/list?area=${areaId}`, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  createProperty(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/property', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  updateProperty(form: any, propertyId: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + `/property/${propertyId}`, form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  getPackageInventory(): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/package-inventory/group', {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getPackageInventoryDetail(packageInventoryGroupId: any): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl +
          `/package-inventory/group/${packageInventoryGroupId}`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getBedTypes(): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/direct-bed-types', {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getMealTypes(): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/direct-meal-types', {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getCancellationPolicies(): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/package-cancellation-policy-rule', {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  createPackageInventory(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/package-inventory/group', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  createBedType(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/direct-bed-types', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  createPackage(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/package', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  editPackage(form: any, packageId: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + `/package/${packageId}`, form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  createRoomType(form: any, propertyId: any): Observable<any> {
    form = { roomTypes: [form] };
    return this.httpClient
      .put(environment.apiUrl + `/property/${propertyId}/room-type`, form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  getMTCountries(): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl +
          `/mt/countries`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getMTCity(country: String): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl +
          `/mt/cities?country=${country}`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getMTProperty(searchKey: any, city: any, mapped: any = false,): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl +
          `/mt/properties?searchKey=${searchKey}&mapped=${mapped}&city=${city}`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }
  getMTRoomType(propertyId: String, mapped: any = false): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl +
          `/mt/properties/${propertyId}/room-types?&mapped=${mapped}`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getMTPackageMapping(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/mt/price-search', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  getRTXPackageMapping(form: any, params?: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + `/rakuten/price-search`, form, {
        params: params,
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  getRoomTypePropertyMapping(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/mt/new-price-search', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  getPackageMappingCheckOrder(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/mt/check-order', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  getRTXPackageAdditional(form: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + '/rakuten/price-search-additional', form, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  getCountries(): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/location/country/list', {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getArea(countryId: string): Observable<any> {
    return this.httpClient
    .get(
      environment.apiUrl + `/location/area/list?country=${countryId}`,
      {
        observe: 'response',
        withCredentials: true,
      }
    )
    .pipe(
      map(
        (res) => {
          return res.body;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  getRTXStateProvinces(countryCode: string): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl + `/rakuten/countries/${countryCode}/stateProvinces`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getRTXCities(stateProvince: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + `/rakuten/stateProvinces/${stateProvince}/cities`, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getRTXProperty(
    city: any,
    searchKey: any,
    mapped: any = false
  ): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl +
          `/rakuten/properties?city=${city}&mapped=${mapped}&searchKey=${searchKey}`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  getRTXRoomType(propertyId: String, mapped: any = false): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrl +
          `/rakuten/properties/${propertyId}/room-types?mapped=${mapped}`,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (res) => {
            return res.body;
          },
          (error) => {
            return error;
          }
        )
      );
  }
}

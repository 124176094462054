import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpModule } from './components/otp/otp.module';
import { LabelSelectModule } from './components/label-select/label-select.module';
import { ActionPanelModule } from './components/action-panel/action-panel.module';
import { SearchBoxModule } from './components/search-box/search-box.module';
import { DatePickerModule } from './components/date-picker/date-picker.module';
import { DateRangePickerModule } from './components/date-range-picker/date-range-picker.module';
import { TabGroupModule } from './components/tab-group/tab-group.module';
import { NotificationModule } from './components/notification/notification.module';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { UserAccountMenuModule } from './components/user-account-menu/user-account-menu.module';
import { DirectiveModule } from './directive/directive.module';
import { UserCategoryModule } from './components/user-category/user-category.module';
import { CompletionScoreModule } from './components/completion-score/completion-score.module';
import { LocationBoxModule } from './components/location-box/location-box.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from './pipe/pipe.module';
import { DialogTemplateModule } from './components/dialog-template/dialog-template.module';
import { TextBoxModule } from './components/base/text-box/text-box.module';
import { WorkInfoModule } from './components/work-info/work-info.module';
import { BasicInfoModule } from './components/basic-info/basic-info.module';
import { PasswordModule } from './components/password/password.module';
import { LastLoginModule } from './components/last-login/last-login.module';
import { FilterSearchBoxModule } from './components/filter-search-box/filter-search-box.module';
import { PhotoUploadBoxModule } from './components/photo-upload-box/photo-upload-box.module';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogModule } from './components/error-dialog/error-dialog.module';
import { ReasonDialogComponent } from './components/reason-dialog/reason-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectSearchInputModule } from './components/mat-select-search-input/mat-select-search-input.module';

@NgModule({
  declarations: [
    TextEditorComponent,
    ConfirmDialogComponent,
    ReasonDialogComponent,
  ],
  imports: [
    CommonModule,
    AngularEditorModule,
    ReactiveFormsModule,
    FormsModule,
    MatStepperModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  exports: [
    OtpModule,
    ActionPanelModule,
    SearchBoxModule,
    DatePickerModule,
    DateRangePickerModule,
    ToolbarModule,
    UserAccountMenuModule,
    UserCategoryModule,
    DirectiveModule,
    LocationBoxModule,
    CompletionScoreModule,
    TranslateModule,
    PipeModule,
    DialogTemplateModule,
    DirectiveModule,
    BasicInfoModule,
    WorkInfoModule,
    PasswordModule,
    LastLoginModule,
    FilterSearchBoxModule,
    TextBoxModule,
    PhotoUploadBoxModule,
    TextEditorComponent,
    ConfirmDialogComponent,
    ErrorDialogModule,
    MatSelectSearchInputModule,
  ],
})
export class SharedModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabGroupComponent } from './tab-group.component';
import {MatTabsModule} from '@angular/material/tabs';



@NgModule({
  declarations: [TabGroupComponent],
  imports: [
    CommonModule,
    MatTabsModule,

  ],
  exports: [
    TabGroupComponent
  ]
})
export class TabGroupModule { }

<mat-accordion>
    <mat-expansion-panel 
        style="cursor: pointer;" 
        [expanded]="isExpanded" 
        (opened)="isExpanded = true"
        (closed)="isExpanded = false">
        <mat-expansion-panel-header             
            expandedHeight="31px"
            collapsedHeight="31px">
            <mat-panel-title>
                <div class="panel-title">
                    <ng-container *ngIf="isExpanded">Hide Actions</ng-container>
                    <ng-container *ngIf="!isExpanded">More Actions</ng-container>
                </div>
            </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="action-container">
            <span (click)="log()" class="action-item">
                Login History
            </span>
            <span (click)="log()" class="action-item action-item--danger">
                Delete User
            </span>
        </div>
       
    </mat-expansion-panel>

</mat-accordion>


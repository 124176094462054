import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiException } from 'src/app/shared/models/api-exception.model';
@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errorMessage = new Subject();
  messageObservable = this.errorMessage.asObservable();
  constructor() { }
  updateErrorMessage(error: any) {
    this.errorMessage.next(error);
  }
  clearErrorMessage() {
    const responseError: ApiException = {
      httpStatus: null,
      errorMessage: null,
      message: null,
      resource: null,
      errorUrl: null,
      exceptionType: null,
      exceptionMessage: null,
      exceptionMethod: null,
    };
    this.errorMessage.next(responseError);
  }
}

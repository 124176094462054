<div style="display: flex;align-items:center;justify-content:center;">
    <button mat-button [matMenuTriggerFor]="menu" style="flex:0 1 100%">
        <div style="
          display:flex;
        align-items:center;
        justify-content:center;">
            <span style="flex:0 1 80%;word-break: break-all; margin-right: 15px">
                {{username}}
            </span>
           <!-- <img style="margin-left:10px;width:50px;height:50px" src="https://www.iconsdb.com/icons/preview/gray/circle-xxl.png"> -->
           <img src="../../../../assets/images/menu-icon.svg">
        </div>
    </button>
</div>
<mat-menu class="user-menu" #menu="matMenu"  xPosition="before"  backdropClass="user-menu-backdrop">
        <div style="display:flex; align-items:center; justify-content:space-between; padding: 15px 20px;">
            <span >
                <div style="margin-bottom: 5px; font-family: 'Nunito', sans-serif !important; font-weight: 600; color: #808080; font-size: 18px;">
                    {{username}}
                </div>
                <div class="role-field role-chip__small" [appRoleLabel]="role">
                    {{role}}
                </div>
            </span>
          <img  src="../../../../assets/images/manage-team-icon.svg">
        </div>
        <div style="background-color: #F8F8F8;">
            <div *ngFor="let item of menuItems; let i = index;let isLast = last;let isFirst = first" class="user-menu-item-container cursor--pointer" [ngClass]="{'user-menu-item-container-last':isLast,'user-menu-item-container-first':isFirst}" (click)="selectedItemIndex.emit(i)">

                    <div class="button-inner-container user-menu-item cursor--pointer ">
                        <img src="../../../../assets/images/{{item?.iconFileName}}-inactive.svg"/>
                        <span class="icon-button-text">{{item.itemName | titlecase}}</span>
                    </div>
            </div>
        </div>

</mat-menu>
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog-templete',
  templateUrl: './alert-dialog-templete.component.html',
  styleUrls: ['./alert-dialog-templete.component.scss']
})
export class AlertDialogTempleteComponent implements OnInit {



  assetPath="../../../../../assets/images/";
  path="";
  isConfirm=false;
  result={};

  constructor(public dialogRef: MatDialogRef<AlertDialogTempleteComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
   
  }

  ngOnInit(): void {
    // this.photoBehaviorObservable.subscribe(x=>{console.log(x,typeof x);});
    if(!!this.data.image){
      this.path=""+this.assetPath+this.data.image;
    }
    if(!!this.data.isConfirm){
      this.isConfirm=true;
      this.result=this.data.isConfirm;
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OtpComponent } from './shared/components/otp/otp.component';
import { SharedModule } from './shared/shared.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SideMenuModule } from './shared/components/side-menu/side-menu.module';
import { HomeComponent } from './pages/home/home.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { WipComponent } from './pages/wip/wip.component';
import { MatButtonModule } from '@angular/material/button';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import {MatStepperModule} from '@angular/material/stepper';

import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { IntegerTextBoxModule } from './shared/components/integer-text-box/integer-text-box.module';
import { PhotoUploadBoxModule } from './shared/components/photo-upload-box/photo-upload-box.module';
import { ApiErrorInterceptor } from './core/http/interceptors/api-error.interceptors';
import { LoadingDialogComponent } from './shared/components/loading-dialog/loading-dialog.component';
import { LoadingInterceptor } from './core/http/interceptors/loading.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorDialogInterceptor } from './core/http/interceptors/error-dialog.interceptor';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WipComponent,
    LoadingDialogComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSidenavModule,
    SideMenuModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    IntegerTextBoxModule,
    MatProgressSpinnerModule,
    PhotoUploadBoxModule,
    MatStepperModule,
    TranslateModule.forRoot(
      {
        defaultLanguage: 'en-US',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    MatCardModule,
    MatDialogModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoibXAtYXJpdGFvbmUiLCJhIjoiY2tkZmRnMnk5MXBwNzJxbDAzNWg0aHc0ciJ9.g7-k2GDwYwe0IZAgiqE7gw',
      geocoderAccessToken: 'pk.eyJ1IjoibXAtYXJpdGFvbmUiLCJhIjoiY2tkZmRnMnk5MXBwNzJxbDAzNWg0aHc0ciJ9.g7-k2GDwYwe0IZAgiqE7gw'
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ApiErrorInterceptor,
    multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorDialogInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss']
})
export class ActionPanelComponent implements OnInit {
  isExpanded = false;

  @HostListener('document:click') outsideClick() {
    console.log(this.isExpanded);
    this.isExpanded = false;
  }

  @HostListener('click', ['$event']) insideClick(e) {
    e.stopPropagation();
  }

  constructor() { }

  ngOnInit(): void { }


  log() {
    console.log(3);
  }
  

}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl, Validators, ValidatorFn, AbstractControl, ɵInternalFormsSharedModule } from '@angular/forms';
import { startWith, map, pluck } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import {AlertDialogTempleteComponent} from '../../shared/components/dialog-template/alert-dialog-templete/alert-dialog-templete.component';
import { ChangePasswordComponent } from 'src/app/shared/components/dialog-template/change-password/change-password.component';
import { ResetPasswordComponent } from 'src/app/shared/components/dialog-template/reset-password/reset-password.component';
import { UserService } from 'src/app/core/http/user.service';
import { LoginService } from 'src/app/core/http/login.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  badgeCount = 1;
  currentUrl = '';
  



  fc = new FormControl('', [Validators.required]);
  fc2 = new FormControl('');




  username = '';
  userEmail = '';
  userId = '';
  role = 'admin';
  menuItems: Array<{itemName, iconFileName, url}>;
  sideItems: Array<{groupName: string, children: Array<{itemName: string, iconFileName: string, url: string}>}>;
  constructor(private location: Location, private router: Router, private translate: TranslateService,public dialog: MatDialog, private userService: UserService, private loginService: LoginService) {
    this.currentUrl = this.location.path();
  }
  
  ngOnInit(): void {

    this.getUserInfo();

    this.location.onUrlChange((url) => {
      this.currentUrl = url;
      console.log(url);
    });

    this.translate.stream(['home.menu']).pipe(pluck('home.menu')).subscribe(res => {
      this.menuItems = [
        { itemName: res.myAccount, iconFileName: 'myaccount', url: '/account/p' },
        // { itemName: res.manageTeam, iconFileName: 'manageteam', url: '/account/t' },
        { itemName: res.signOut, iconFileName: 'signout', url: '/login' },
      ];
      this.sideItems = [
        // {
        //   groupName: res.overview,
        //   children: [{itemName: res.dashboard, iconFileName: 'dashboard', url: '/dashboard'}]
        // },
        {
          groupName: res.main,
          children: [
            // { itemName: res.properties, iconFileName: 'properties', url: '/properties' },
            // { itemName: res.customer, iconFileName: 'customer', url: '/customer' },
            { itemName: res.booking, iconFileName: 'booking', url: '/booking' },
            { itemName: res.tour, iconFileName: 'tour', url: '/tour' },
            { itemName: res.properties, iconFileName: 'properties', url: '/properties' },
            { itemName: res.packageInfo, iconFileName: 'package', url: '/package' },
            
            // { itemName: res.package, iconFileName: 'package', url: '/package' },
          ]
        }
      ];
    });

    // const dialogRef7 = this.dialog.open(ChangePasswordComponent,{
    //   data:{title:'Change Password'},
    //   panelClass:"alert-message-dialog",
    // });
    // const dialogRef = this.dialog.open(ResetPasswordComponent,{
    //   data:{title:'Change Password'},
    //   panelClass:"alert-message-dialog",
    // });
    // const dialogRef2 = this.dialog.open(AlertDialogTempleteComponent,{
    //   data:{title:'Change Password',content:"Password has been changed successfully!",image:"change-password.png"},
    //   panelClass:"alert-message-dialog",
      
    // });
    // const dialogRef3 = this.dialog.open(AlertDialogTempleteComponent,{
    //   data:{title:'Authorizing User Accesses',content:"Admin level has been updated!",image:"edit-user-level.png"},
    //   panelClass:"alert-message-dialog",
    // });
    // const dialogRef4 = this.dialog.open(AlertDialogTempleteComponent,{
    //   data:{title:'Delete User',content:`User <span class="keyword">Jade Chan</span> has been deleted!`,image:"delete-user.png"},
    //   panelClass:"alert-message-dialog",
      
    // });
    // const dialogRef5 = this.dialog.open(AlertDialogTempleteComponent,{
    //   data:{title:'Add User',content:`User <span class="keyword">Vivan Poon</span> has been added!`,image:"add-user.png"},
    //   panelClass:"alert-message-dialog"
      
    // });
    // const dialogRef6 = this.dialog.open(AlertDialogTempleteComponent,{
    //   data:{title:'Authorizing User Accesses',content:`Are you sure to change this user to <span class="keyword">super admin</span> ,which authorizes him/her more user accesses?`,isConfirm:true},
    //   panelClass:"alert-message-dialog",
    // });


    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  add() {
    this.badgeCount++;
  }
  minus() { this.badgeCount--; }

  navigateByMenuItem(e) {
    console.log(e);
    if(this.menuItems[e]?.url === '/login') {
      this.loginService.logout().subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      });
      this.router.navigate(['/login']);
    }
    else {
      this.router.navigate([this.menuItems[e]?.url]);
    }
    
  }

  getUserInfo() {
    this.userService.getUserInfo().subscribe(res => {
      this.userEmail = res["userEmail"];
      this.username = res["userName"];
      this.userId = res["userId"];
    }, error => {
      console.log(error);
    })
  }

}

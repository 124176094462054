<mat-card class="info-contianer mat-elevation-z2">
    <mat-card-content>
        <div class="info-title">
            <ng-container *ngIf="isEditable; else basicInfoTitle">
                <span>
                    {{'basicInfo.title.editBasicInfo' | translate}}
                </span>
            </ng-container>
            <ng-template #basicInfoTitle>
                <span>
                    {{'basicInfo.title.basicInfo' | translate}}
                </span>
            </ng-template>
            <span class="info-error" *ngIf="isEditable && basicInfoForm.invalid">
                {{'error.msg.invalidForm' | translate}}
            </span>
            <img class="info-edit" *ngIf="!isEditable && !isProfile" (click)="editBasicInfo()" src="../assets/images/edit.png">
            <div style="float: right" *ngIf="!isProfile">
                <ng-container *ngIf="isEditable; else roleBox">
                    <div style="width: 143px; ">
                        <app-label-select [control]="roleInput" [options]="roleOptions"></app-label-select>
                    </div>
                </ng-container>
                <ng-template #roleBox>
                    <div class="role-chip" [ngClass]="{ 'role-chip--super-admin': selectedRole?.roleValue === AdminRoleType.SUPER_ADMIN, 'role-chip--admin': selectedRole?.roleValue === AdminRoleType.ADMIN}">
                        {{selectedRole?.translateKey | translate}}
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="info-body">
            <div class="info-icon">
                <img [src]="displayIconUrl">
            </div>
            <div class="info-form">
                <div class="info-row">
                    <div class="info-col">
                        <span class="info-label">{{'basicInfo.lbl.displayName' | translate}}</span>
                        <span class="info-field">
                            <ng-container *ngIf="!isEditable; else displayNameBox">{{displayName}}</ng-container>
                            <ng-template #displayNameBox>
                                <div style="width: 201px;">
                                    <app-text-box [control]="displayNameInput"></app-text-box>
                                </div>
                            </ng-template>
                        </span>
                    </div>
                    <div class="info-col">
                        <span class="info-label">{{'basicInfo.lbl.birthDate' | translate}}</span>
                        <span class="info-field">

                            <ng-container *ngIf="!isEditable; else birthDayBox">
                                {{birthDay}}&nbsp;{{selectedMonth}}
                            </ng-container>
                            <ng-template #birthDayBox>
                                <div style="display: grid; grid-template-columns: 54px 115px; column-gap: 5px;">
                                    <app-text-box [control]="birthDayInput"></app-text-box>
                                    <app-select-box [control]="birthMonthInput" [options]="monthOptions">
                                    </app-select-box>
                                </div>
                            </ng-template>
                        </span>
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-col">
                        <span class="info-label">{{'basicInfo.lbl.email' | translate}}</span>
                        <span class="info-field">
                            <ng-container *ngIf="!isEditable; else emailBox">{{email}}</ng-container>
                            <ng-template #emailBox>
                                <div [class.field-text]="isEditable" style="width: 201px;">
                                    {{email}}
                                </div>
                            </ng-template>
                        </span>
                    </div>
                    <div class="info-col">
                        <span class="info-label">{{'basicInfo.lbl.mobileNumber' | translate}}</span>
                        <span class="info-field">
                            <ng-container *ngIf="!isEditable; else mobileNumberBox">{{mobileNumber}}</ng-container>
                            <ng-template #mobileNumberBox>

                                <div style="display: grid; grid-template-columns: 72px 124px; column-gap: 5px;">

                                    <app-select-box [options]="countryOptions" [control]="countryIdInput">
                                    </app-select-box>

                                    <app-text-box [control]="mobileNumberInput"></app-text-box>
                                </div>
                            </ng-template>
                        </span>
                    </div>
                </div>
               
                <div class="info-password">
                    
                    <span *ngIf="isProfile && !isEditable" (click)="changePasswordClicked.emit(true)">
                        {{'basicInfo.btn.changePassword' | translate}}
                    </span>
                    <span *ngIf="!isProfile && !isEditable" (click)="resetPasswordClicked.emit(true)">
                        {{'basicInfo.btn.resetPassword' | translate}}
                    </span>
                </div>
                <div *ngIf="isProfile" class="info-remark">{{'basicInfo.msg.remark' | translate}}</div>
            </div>
        </div>
        <div class="info-footer">
            <div class="info-submit" *ngIf="isEditable">
                <button class="cancel-btn" (click)="cancel()" type="button" mat-flat-button                
                 >{{'basicInfo.btn.cancel' | translate }}</button>

                <button class="save-btn" (click)="saveChanges()" type="button" mat-flat-button
                    [disabled]="!basicInfoForm.dirty">{{'basicInfo.btn.saveChanges' | translate }}</button>
            </div>

        </div>
    </mat-card-content>

</mat-card>
<div class="alert-dialog-container" >
    <div class="alert-dialog-title" style="display:flex;flex-direction: row;justify-items: stretch;">
        <span style="flex:0 1 100%; word-break:break-all;" class="alert-message-dialog-title">
            {{data.title}}
        </span>
        <span class="cursor--pointer" (click)="onNoClick()">
            <img src="../../../../../assets/images/close-button.svg" />
        </span>
    </div>
    <div  style="display:flex;flex-direction: column;align-items: center;">
        <div>
            <img *ngIf="path" class="alert-dialog-image" [src]="path" />
        </div>
        <div class="alert-dialog-content" [ngClass]="{'text-align-center':!isConfirm,'text-align-left':!isConfirm,'alert-dialog-content-message':!isConfirm,'alert-dialog-content-confirm':!!isConfirm}"  [innerHTML]="data.content">
        </div>
    </div>
    <div *ngIf="isConfirm"  class="alert-dialog-button" style="display:flex;flex-direction: row;justify-content: space-between;">
            <button  class="pair-button button--cancel mat-elevation-z0" type="button" (click)="onNoClick()" [disableRipple]="true" mat-raised-button>
                <span class="button-text--cancel">
                    Cancel
                </span></button>
            <button  class="pair-button button--confirm mat-elevation-z0" type="button" (click)="onConfirm()" [mat-dialog-close]="data.isConfirm" [disableRipple]="true" mat-raised-button>
                <span class="button-text--confirm">
                    Confirm
                </span>
            </button>
    </div>
</div>

<mat-card class="photo-container mat-elevation-z2">
    <div class="title-container">
        <span class="title">{{title}}</span>
        <button mat-icon-button *ngIf="!isInEditMode" (click)="isInEditMode = true">
            <mat-icon style="color: #b96d5a; font-size: large;">create</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isInEditMode" (click)="isInEditMode = false">
            <mat-icon style="color: #b96d5a; font-size: large;">save</mat-icon>
        </button>        
    </div>
    
    <mat-card-content>
        <div cdkDropList cdkDropListOrientation="horizontal" #imageList="cdkDropList" class="scrollmenu" (cdkDropListDropped)="drop($event)">
            <span *ngFor="let image of images;" cdkDrag [cdkDragDisabled]="!isInEditMode">
                <img class="card-image" [src]="image.url" />
                <button class="close-button" matTooltip="Delete Photo" [matTooltipPosition]="'above'" *ngIf="isInEditMode" (click)="removeImage(images.indexOf(image))">
                    <mat-icon style="color: #b96d5a; font-size: large;">close</mat-icon>
                </button>
                <!-- <span>{{image.description}}</span> -->
            </span> 
            <span *ngIf="isInEditMode">
                <img class="card-image" style="cursor: pointer;" src="../../../../assets/images/photo-upload.png" (click)="selectImage.click()" />
                <input type="file" (change)="updateImage($event)" style="display: none" multiple="isMultiple" #selectImage />
            </span>
        </div>        
    </mat-card-content>
</mat-card>

<h1 mat-dialog-title class="text--primary">{{title}}</h1>
<div mat-dialog-content>
  <div class="">{{data.message}}</div>
  
</div>
<div mat-dialog-actions>
    <div style="margin-left: auto; margin-top: 50px;">
        <button mat-stroked-button style="margin-right: 10px;" (click)="onNoClick()" color="primary" cdkFocusInitial>Cancel</button>
        <button mat-flat-button (click)="onYesClick()" color="primary">Confirm</button></div>
    
</div>
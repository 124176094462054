// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  otpInterval: 1000,
  otpDebounceSeconds: 30,
  // url: 'http://18.140.22.74:3334/myfamigo-admin/v1',
  // apiUrl: "http://admin-test.myfamigo.com:2226/myfamigo-mini-manual-admin-api/v1"
  apiUrl: "https://admin.myfamigo.com:8443/myfamigo-mini-manual-admin-api/v1"
  // apiUrl: "http://18.140.22.74:2226/myfamigo-mini-manual-admin-api/v1"
  // apiUrl: "http://127.0.0.1:8080/myfamigo-mini-manual-admin-api/v1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

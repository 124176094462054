import { Directive, ElementRef, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appRoleLabel]'
})
export class RoleLabelDirective implements OnInit {

@Input('appRoleLabel') role:string ;

  constructor(private el: ElementRef) { 

  }
  ngOnInit(){
    if(this.role.toLowerCase()=="super admin")
      this.el.nativeElement.classList.add("role-chip--super-admin");
      else{
        this.el.nativeElement.classList.add("role-chip--"+this.role);
    }
  }


  
}

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';

@Injectable()
export class ErrorDialogInterceptor implements HttpInterceptor {
  dialogRef: any = null;
  constructor(private dialog: MatDialog,
    private router: Router,) {


  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(

      catchError((err: HttpErrorResponse) => {
        if (request.responseType === 'blob') {
          const reader = new FileReader();
          reader.onloadend = (e) => {
            const jsonError = JSON.parse(reader.result as string);
            if (this.dialogRef === null) {
              this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                width: '350px',
                disableClose: true,
                autoFocus: false,
                data: { title: 'Error', message: jsonError?.resource ?? '系統錯誤，請與系統管理員聯絡。', type: err.error?.exceptionType ?? '-1' }
              });
              this.dialogRef.afterClosed().subscribe((result: boolean) => {
                this.dialogRef = null;
              });
            }
          }
          reader.readAsText(err.error);
        } else {
          if (this.dialogRef === null) {
            if (err.status == 401 ) {
              this.router.navigate(['/login']);
            } else {
              this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                width: '350px',
                disableClose: true,
                autoFocus: false,
                data: { title: 'Error', message: err.error?.resource ?? '系統錯誤，請與系統管理員聯絡。', type: err.error?.exceptionType ?? '-1' }
              });
              this.dialogRef.afterClosed().subscribe((result: boolean) => {
                this.dialogRef = null;
              });
            }
            
          }

        }
        return throwError(err);
      })
    );
  }
}



<div class="alert-dialog-container" >
    <div class="reset-password-dialog-title-container" style="display:flex;flex-direction: row;justify-items: stretch;">
        <span class="reset-password-dialog-title" style="flex:0 1 100%; word-break:break-all;" >
            {{data.title}}
        </span>
        <span class="cursor--pointer" (click)="onNoClick()">
            <img src="../../../../../assets/images/close-button.svg" />
        </span>
    </div>
    <ng-template cdkPortalOutlet #portalOutlet="cdkPortalOutlet">
                
    </ng-template>
</div>

import { Directive, HostListener, ViewContainerRef, OnInit,AfterContentInit,AfterContentChecked,AfterViewChecked,AfterViewInit, ContentChildren, QueryList, ContentChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { MenuScrollService } from 'src/app/core/services/menu-scroll/menu-scroll.service';
import { ScrollMenuItemDirective } from './scroll-menu-item.directive';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Directive({
  selector: '[appScrollMenu]'
})
export class ScrollMenuDirective implements AfterViewInit, OnDestroy, OnInit,AfterContentInit,AfterContentChecked,AfterViewChecked {

@Input() set index(i : number){
  if(!!this.items)
  {
    this.items.toArray()[i['index']]?.viewContainerRef.element.nativeElement.scrollIntoView();
  }
}

@ContentChildren(ScrollMenuItemDirective) items:QueryList<ScrollMenuItemDirective>;
    @HostListener('scroll', ['$event'])
    onScroll(event) {
        this.menuScrollService.setMenuIndex(this.items.filter((y)=>this.viewContainerRef.element.nativeElement.scrollTop>=(y.viewContainerRef.element.nativeElement.offsetTop)).length);
    }

  constructor(private menuScrollService:MenuScrollService,public viewContainerRef: ViewContainerRef ) {
    
  
   }
  ngAfterViewChecked(): void {
    console.log('ngAfterViewChecked');
  }
  ngAfterContentChecked(): void {
    console.log('ngAfterContentChecked');
  }
  ngOnInit(): void {
    console.log('ngOnInit');
  }
  ngAfterContentInit(): void {
    console.log('ngAfterContentInit');
  }
  ngOnDestroy(): void {
    this.menuScrollService.resetListIndex();
    this.menuScrollService.resetMenuIndex();
  }
    ngAfterViewInit(): void {
      console.log('ngAfterViewInit');
      //  this.items.toArray()[1].viewContainerRef.element.nativeElement.scrollIntoView();
    }

}
import { Component, OnInit, Input, AfterViewInit, AfterViewChecked, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-enter-password',
  templateUrl: './enter-password.component.html',
  styleUrls: ['./enter-password.component.scss']
})
export class EnterPasswordComponent implements OnInit,AfterViewInit,OnDestroy{

  @Output() onDestroy = new EventEmitter<string>();
  @Output() result = new EventEmitter<boolean>();

  passwordForm: FormGroup= this.fb.group({
    previousMagic: ['']
  });



  hide = true;
  isError=false;

  constructor(private fb: FormBuilder) {
    console.log("constructor");
    
  }
  ngAfterViewInit(): void {
    
  }
  
  
  ngOnDestroy(): void {
    console.log("destroy");
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit(): void {
    console.log("ngOnInit");
    
  }

  verifyPassword(){
    this.result.emit(true);
    // this.setErrorState();
  }

  setErrorState(){
    this.isError=true;
    // this.passwordForm.markAsUntouched();
    this.passwordForm.reset();
  }

  get previousMagic(){
    return this.passwordForm.get('previousMagic');
  }

}

import { RoleFactory } from './role.factory';
import { AdminRole } from 'src/app/shared/models/role/base/admin-role.model';
import { Admin } from 'src/app/shared/models/role/base/admin.model';
import { AdminRoleType } from 'src/app/shared/enums/role/admin-role-type.enum';
import { SuperAdmin } from 'src/app/shared/models/role/base/super-admin.model';
import { inject } from '@angular/core/testing';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdminRoleFactory implements RoleFactory {
    createRole(type: AdminRoleType): AdminRole {
       let adminType;
       switch (type) {
            case AdminRoleType.SUPER_ADMIN:
                adminType = new SuperAdmin();
                break;
            case AdminRoleType.ADMIN:
                adminType = new Admin();
                break;
            default:
                break;
       }
       return adminType;
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TabGroupModule } from '../tab-group/tab-group.module';
import { NotificationModule } from '../notification/notification.module';
import {MatDividerModule} from '@angular/material/divider';
import { UserAccountMenuModule } from '../user-account-menu/user-account-menu.module';


@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    TabGroupModule,
    NotificationModule,
    MatDividerModule,
    UserAccountMenuModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule { }

<mat-sidenav-container style="height:100%;">
    <mat-sidenav mode="side" opened style="width:216px;" class="mat-elevation-z2">
        <div style="margin-top: 20px; text-align: center;">
            <img src="../../assets/images/logo.png">
        </div>
        <div style="margin-top: 36.96px">
            <app-side-menu [url]="currentUrl" [sideItems]="sideItems"></app-side-menu>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div style="display: grid; grid-template: 64px auto / 1fr; height: 100%">
            <div>
                <app-toolbar [menuItems]="menuItems" [role]="role" [username]="username"
                    (selectedItemIndex)="navigateByMenuItem($event)"></app-toolbar>
            </div>  
    
            <div style="overflow-y: hidden;">
    
                <!-- <app-action-panel></app-action-panel>
               
    
                    <app-completion-score score="75"></app-completion-score>
                
                <app-date-picker [control]="fc2"></app-date-picker>
                <app-label-select [options]="[{'color': '#FFFF00', value: 'A', viewValue:'A'}]" [control]="fc"></app-label-select> -->
    
                <router-outlet></router-outlet>
            </div>
        </div>

        <!-- <div style="display:flex; flex-direction: row;">
           
            <div style=" background-color: aqua; flex: 0 1 20%; word-break:break-all;" >
              
                flex box example with long text. max width = 20% only
            </div>
            <div *ngIf="!!flexBox" style=" background-color: yellow; flex: 1 0 90%; word-break:break-all;">
             
                flex box example with long text. trigger by 20% box
            </div>
        </div> -->

      <!-- <div  style="height:60%;display:flex; flex-direction: row; ">
            <div style="width:20%;display:flex;flex-direction: column;">
                <app-user-category style="font-size:20px;flex: 0 0 " >
                </app-user-category>
                <app-user-list class="scrollbar"  style="flex: 1 0 "></app-user-list>
            </div>

            <mat-card class="example-card">
       
                <mat-card-content style="width:100%;height:100%">
                  <p>
                    The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                    A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
                    bred for hunting.
                  </p>
                </mat-card-content>
             
              </mat-card>


            
            <div style=" background-color: aqua; flex: 0 1 20%; word-break:break-all;" (click)="flexTest()">
               
                flex box example with long text. max width = 20% only
            </div>
            <div *ngIf="!!flexBox" style=" background-color: yellow; flex: 1 0 90%; word-break:break-all;">
          
                flex box example with long text. trigger by 20% box
            </div>
        </div>  -->


        <!-- <div style="display:flex; flex-direction: column; ">
            <app-user-category style="font-size:20px;width:20%;flex: 1 0" >
            </app-user-category>
            
        </div>
        <div class="scrollbar" style="height:60%;width:20%; display:flex; flex-direction: row; ">
            <app-user-list style="flex: 1 0  "></app-user-list>
        </div>
        <div style="display:flex; flex-flow: row wrap;">
            <span style="background-color: red;word-break: break-all;">1234567890</span>
            <span style="background-color: green;word-break: break-all">0987654321</span>
            <span style="background-color: yellow;word-break: break-all">6789054321</span>
        </div>  -->
        <!-- <app-date-picker></app-date-picker> -->

       
    </mat-sidenav-content>
</mat-sidenav-container>
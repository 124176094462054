import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-account-menu',
  templateUrl: './user-account-menu.component.html',
  styleUrls: ['./user-account-menu.component.scss']
})
export class UserAccountMenuComponent implements OnInit {
  @Input() username;
  @Input() role;
  @Input() avatar;
  @Input() menuItems = new Array();
  @Output() selectedItemIndex = new EventEmitter<number>();
  constructor() {
    console.log(this.menuItems);
  }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastLoginComponent } from './last-login.component';
import { PipeModule } from '../../pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [LastLoginComponent],
  imports: [
    CommonModule,
    PipeModule,
    TranslateModule
  ],
  exports:[LastLoginComponent]
})
export class LastLoginModule { }

import { Component, OnInit, Inject, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { UserService } from 'src/app/core/http/user.service';
import { AlertDialogTempleteComponent } from '../alert-dialog-templete/alert-dialog-templete.component';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  destroy$ = new Subject();
  passwordForm: FormGroup;
  hide = true;
  hide2 = true;
  hide3 = true;
  isError = false;
  isNotMatch = true;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private accountService: UserService
  ) {
    this.passwordForm = this.fb.group({
      magic: [''],

    })
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  ngOnInit(): void {
  }

  changePassword() {

    this.accountService.changePassword(this.passwordForm.controls.magic.value).subscribe((res) => {

      const dialogRef = this.dialog.open(AlertDialogTempleteComponent, {
        data: { title: 'Successful', content: res.message },
        panelClass: "alert-message-dialog"
      });

      this.dialogRef.close();
    }, error => {
      const dialogRef = this.dialog.open(AlertDialogTempleteComponent, {
        data: { title: 'Submission Error', content: error.exceptionMessage },
        panelClass: "alert-message-dialog"
      });
    })




  }

  ngAfterViewInit() {

  }

  resetForm() {
    this.passwordForm.reset();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get magic() {
    return this.passwordForm.get('magic');
  }
  get confirmMagic() {
    return this.passwordForm.get('confirmMagic');
  }
  get previousMagic() {
    return this.passwordForm.get('previousMagic');
  }
}

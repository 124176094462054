import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
    }


  ngOnInit(): void {

  }

  onOkClick(): void {
    
    
    if(this.data.type === '-9002') {
      this.router.navigate(['/login']);
    }
    this.dialogRef.close();
  }
}

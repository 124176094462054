import { AdminRole } from './admin-role.model';
import { AdminRoleType } from '../../../enums/role/admin-role-type.enum';
import { AdminRoleName } from '../../../enums/role/admin-role-name.enum';
import { AdminRoleColor } from '../../../enums/role/admin-role.color.enum';

export class Admin extends AdminRole {
    
    constructor() {
        super(AdminRoleType.ADMIN, AdminRoleName.ADMIN, AdminRoleColor.ADMIN);
    }   
}
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap, debounce } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  loginUser(email, password): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/user/login', {
      email: email, password: password 
    },
    {
      observe: 'response',
      withCredentials: true,
    }).pipe(map(res => {
      localStorage.setItem('userId', res.body.userId);
      console.log(res);    
    }, err => {
      console.log(err);
    }));
  }

  logout(): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/user/logout', {},
    {
      observe: 'response',
      withCredentials: true,
    }).pipe(map(res => {
      console.log(res);     
    }, err => {
      console.log(err);
    }));
  }

  verifyOTP(otp: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/users/opt/verify', { otp }, {
      observe: 'body',
      withCredentials: true,
    });
  }
  resendOTP(): Observable<any> {
    return this.http.post(environment.apiUrl + '/users/opt/resend', {}, {
      observe: 'body',
      withCredentials: true,
    });
  }
}

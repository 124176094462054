import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionPanelComponent } from './action-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
  declarations: [ActionPanelComponent],
  imports: [
    CommonModule,
    MatExpansionModule
  ],
  exports: [ActionPanelComponent]
})
export class ActionPanelModule { }

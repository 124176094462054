import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCategoryComponent } from './user-category.component';
import { DirectiveModule } from '../../directive/directive.module';



@NgModule({
  declarations: [UserCategoryComponent],
  imports: [
    CommonModule,
    DirectiveModule
  ],
  exports:[
    UserCategoryComponent
  ]
})
export class UserCategoryModule { }

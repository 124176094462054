import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegerTextBoxComponent } from './integer-text-box.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [IntegerTextBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule
  ],
  exports:[IntegerTextBoxComponent]
})
export class IntegerTextBoxModule { }

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnChanges {
  @Input() url = '';
  @Input() sideItems = Array<{groupName: string, children: Array<{itemName: string, iconFileName: string, url: string}>}>();
  matchUrls = [];

  constructor() { }
  

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.matchUrls = this.sideItems?.reduce((prev: any,curr: any) => { 
      prev.push(...curr.children);
      return prev;
    }, []).map(p => this.url.includes(p.url));
  }
  
}

import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnChanges {
  @Input() badgeCount;
  isHidden = false;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.isHidden = changes?.badgeCount?.currentValue <= 0;
  }

  ngOnInit(): void {
  }

  
}

import { Component, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';


function existOption(myArray: any[]): ValidatorFn {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    const selectboxValue = c.value;
    console.log(selectboxValue , '--temp')

    const pickedOrNot = myArray.filter(p => {
        return [selectboxValue?.toString().toLowerCase(), selectboxValue?.name?.toLowerCase()]
          .includes(p.name.toLowerCase());
      }
    );
    console.log(pickedOrNot);
    
    return pickedOrNot.length > 0 ? null : { match: true } ;
  };
}
@Component({
  selector: 'app-location-box',
  templateUrl: './location-box.component.html',
  styleUrls: ['./location-box.component.scss']
})
export class LocationBoxComponent implements OnInit {
  options: any[] = [{name: 'One'}, {name: 'Two'}, {name: 'Three'}];

  myControl = new FormControl('', []);

  filteredOptions = [];
  isPanelOpened = false;
  constructor() {
    
   }

  ngOnInit(): void {
    const currentValidators = this.myControl.validator instanceof Array ? [...this.myControl.validator] : [];
    currentValidators.push(existOption(this.options));
    this.myControl.setValidators(currentValidators);

    this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.getMatchedOptions(value))
    ).subscribe();
  }

  getMatchedOptions(value: any) {
    const filterValue = value?.name?.toLowerCase() || value?.toLowerCase();
    this.filteredOptions = this.options.filter(option => option?.name.toLowerCase().indexOf(filterValue) === 0);
    console.log(this.filteredOptions);
    
  }
  displayWith(val: any) {
    console.log(val, '--display');
    return val?.name;
  }
  

  selectFirstOption() {
    console.log(this.myControl.value, '---check');
    const t = this.myControl.value;
    if (typeof t === 'string' && t?.length > 0) {
      const firstMatch = this.options.find(o => o.name.toLowerCase().includes(t.toLowerCase())) ?? '';
      this.myControl.setValue(firstMatch);
    } else {

    }
  }
}

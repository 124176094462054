import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService } from '../../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  isCompleted = false;
  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.isCompleted = false;
    this.loadingService.showLoading();
   
    return next.handle(request).pipe(
      finalize(() => {
        this.isCompleted = true;
        this.loadingService.closeLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.isCompleted = true;
        this.loadingService.closeLoading();
        return throwError(err);
      })
    );
  }
}
 
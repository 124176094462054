<div class="menu-container">
    
    <div class="menu-group" *ngFor="let p of sideItems; let i = index">
        <div class="menu-group-name">
            {{p.groupName}}
        </div>    
        <div class="menu-item" *ngFor="let c of p.children; let y = index" 
            [routerLink]="[c.url]"
            [class.menu-item--active]="matchUrls[i+y]">
            <div class="menu-item-icon">
                <img *ngIf="!matchUrls[i+y]" loading="lazy" src="../../../../assets/images/{{c.iconFileName}}-inactive.png">
                <img *ngIf="matchUrls[i+y]" loading="lazy" src="../../../../assets/images/{{c.iconFileName}}-active.png">
            </div>
            <div class="menu-item-name" >{{ c.itemName }}</div>    
        </div>
    </div>
</div>
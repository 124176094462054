import { Component, OnInit, Output ,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-user-category',
  templateUrl: './user-category.component.html',
  styleUrls: ['./user-category.component.scss']
})
export class UserCategoryComponent implements OnInit {

  constructor() { }
  selectedIndex=null;
  @Output() indexChange = new EventEmitter<string>();

  userCategoryList = [
    {
      name:"All",
      value:"99"
    },
    {
      name:"Business",
      value:"0"
    },
    {
      name:"Development",
      value:"1"
    }
  ];

  selectIndex(index){
    this.selectedIndex=index;
    this.indexChange.emit(this.selectedIndex);
  }

  ngOnInit(): void {
  }
}

export interface userCategoryConfig {
  name?: string;
  value?:string;
}
<mat-toolbar style="background: #FFFFFF;" class="mat-elevation-z2">
    <div style="width: 100%; display: flex; align-self: stretch;">
        <app-tab-group style="align-self: flex-end;" [tabs]="tabs" (tabSelectedChange)="tabChange($event)"></app-tab-group>
    </div>
    <div style="display: flex;">
        <app-notification [badgeCount]="0" style="margin-right: 15px;"></app-notification>
        
    </div>
    <div style="display: flex; border-right: 1px solid #D4D4D4; height:39.5px; margin-right: 10px; ">        
    </div>
    <div>
        <app-user-account-menu [menuItems]="menuItems" [role]="role" [username]="username" (selectedItemIndex)="selectedItemIndex.emit($event)"></app-user-account-menu>
    </div>
</mat-toolbar>
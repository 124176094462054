import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import { UserAccountMenuComponent } from './user-account-menu.component';
import {MatButtonModule} from '@angular/material/button';
import { DirectiveModule } from '../../directive/directive.module';



@NgModule({
  declarations: [UserAccountMenuComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatButtonModule,
    DirectiveModule
  ],
  exports: [
    UserAccountMenuComponent
  ]
})
export class UserAccountMenuModule { }

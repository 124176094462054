import { Component, OnInit, Inject, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { EnterPasswordComponent } from '../components/enter-password/enter-password.component';
import { NewPasswordComponent } from '../components/new-password/new-password.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit,OnDestroy {


  @ViewChild('portalOutlet') portalOutlet: CdkPortalOutlet;
  componentRef: ComponentRef<any>;
  enterPasswordPortal: ComponentPortal<EnterPasswordComponent>
  newPasswordComponent: ComponentPortal<NewPasswordComponent>
  destroy$ = new Subject();
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private resolver: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef
  ) {
  }
  ngOnDestroy(): void {
    console.log("destroy");
    this.componentRef=null;
    this.enterPasswordPortal=null;
    this.newPasswordComponent=null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void { }


  ngAfterViewInit() {
    this.enterPasswordPortal = new ComponentPortal(EnterPasswordComponent);
    this.newPasswordComponent = new ComponentPortal(NewPasswordComponent);
    this.setPortalRef(this.enterPasswordPortal);
    this.cdRef.detectChanges();
    this.componentRef.instance.result.pipe(takeUntil(this.componentRef.instance.onDestroy),takeUntil(this.destroy$)).subscribe(x => { 
      if(!!x)
        this.setPortalRef(this.newPasswordComponent);
      this.dialogRef.updateSize();
    });
  }



  setPortalRef(portal: ComponentPortal<any>) {
    if (this.portalOutlet.hasAttached)
      this.portalOutlet.detach();
    this.componentRef = this.portalOutlet.attach(portal);
  }

  // temp(){

  //   // const viewContainerRef = this.componentHost.viewContainerRef;
  //   this.vcRef.clear();
  //   if(this.componentRef)
  //     this.componentRef.destroy();
  //   this.createConponent();
  // }

  // createConponent(){
  //   const factory = this.resolver.resolveComponentFactory(ResetPasswordComponent);
  //   this.componentRef = this.vcRef.createComponent(factory);
  //   this.componentRef.instance
  //   console.log(this.componentRef);
  //   this.componentRef.instance.indexChange.subscribe(x=>{console.log(x)});
  // }


  onNoClick(): void {
    this.dialogRef.close();
  }
}

<mat-card class="info-contianer mat-elevation-z2">
    <mat-card-content>
        <div class="info-title">
            <ng-container *ngIf="isEditable; else workInfoTitle">
                <span>
                    {{'workInfo.title.editWorkInfo' | translate}}
                </span>
            </ng-container>
            <ng-template #workInfoTitle>
                <span>
                    {{'workInfo.title.workInfo' | translate}}
                </span>
            </ng-template>
            <span class="info-error" *ngIf="isEditable && workInfoForm.invalid">
                {{'error.msg.invalidForm' | translate}}
            </span>
            <img class="info-edit" *ngIf="!isEditable && !isProfile" (click)="editWorkInfo()" src="../assets/images/edit.png">
        </div>
        <div class="info-body">
            <div class="info-form">
                <form>
                    <div class="info-row">
                        <div class="info-col">
                            <span class="info-label" >{{'workInfo.lbl.staffId' | translate}}</span>
                            <span class="info-field">
                                <div [class.field-text]="isEditable" >
                                    {{staffId}}
                                </div>
                            </span>
                        </div>
                        <div class="info-col">
                            <span class="info-label" >{{'workInfo.lbl.workSince' | translate}}</span>
                            <span class="info-field">
                                <div *ngIf="!isEditable; else workSinceBox;">{{workSince | date: 'dd MMM yyyy'}}</div>
                                <ng-template #workSinceBox>
                                    <div class="field-text" style="width: 201px;">
                                        {{workSince | date: 'dd MMM yyyy'}}
                                    </div>
                                </ng-template>
                            </span>
                            
                        </div>
                    </div>        
                    <div class="info-row">  
                        <div class="info-col">
                            <span class="info-label" >{{'workInfo.lbl.designation' | translate}}</span>
                            
                            <span class="info-field">
                                <div *ngIf="!isEditable; else designationBox;">{{designation}}</div>
                                <ng-template #designationBox>
                                <div style="width: 201px;">

                                    <app-text-box [control]="designationInput"></app-text-box>
                                    </div>
                                </ng-template>
                            </span>
                            
                        </div>                         
                        <div class="info-col">
                            <span class="info-label" >{{'workInfo.lbl.department' | translate}}</span>
                            <span class="info-field">
                                <ng-container *ngIf="!isEditable; else departmentBox;">{{department}}</ng-container>
                                <ng-template #departmentBox>
                                   <div style="width: 201px;">
                                       <app-select-box [control]="departmentInput" [options]="departmentOptions"></app-select-box>
                                    </div>
                                </ng-template>
                            </span>
                        </div>
                    </div>   
                
                </form>
            </div>
        </div>
        <div class="info-footer">
            <div class="info-submit" *ngIf="isEditable">
                <button class="cancel-btn" (click)="cancel()" type="button" mat-flat-button                
                >{{'basicInfo.btn.cancel' | translate }}</button>

                <button class="save-btn" (click)="saveChanges()" type="button" mat-flat-button [disabled]="workInfoForm.invalid || !workInfoForm.dirty"
                    >{{'workInfo.btn.saveChanges' | translate }}</button>
            </div>
           
        </div>
    </mat-card-content>

</mat-card>
import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges, ChangeDetectorRef, DoCheck } from '@angular/core';

@Component({
  selector: 'app-completion-score',
  templateUrl: './completion-score.component.html',
  styleUrls: ['./completion-score.component.scss'],
})
export class CompletionScoreComponent implements OnInit, OnChanges{
  @Input() score = 0;
  spinnerColorClass = '';
  textColorClass = '';

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    const currentScore = changes.score.currentValue;
    if (currentScore >= 80) {
      this.spinnerColorClass = 'circle-progress--success';
      this.textColorClass = 'circle-score--success';
    } else if (currentScore >= 50) {
      this.spinnerColorClass = 'circle-progress--warn';
      this.textColorClass = 'circle-score--warn';
    } else {
      this.spinnerColorClass = 'circle-progress--danger';
      this.textColorClass = 'circle-score--danger';
    }
    console.log(this.spinnerColorClass);
    
  }
  ngOnInit(): void {
  }

}

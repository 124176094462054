
    <div class="alert-dialog-container">
        <div class="alert-dialog-title" style="display:flex;flex-direction: row;justify-items: stretch;">
            <span style="flex:0 1 100%; word-break:break-all;" class="alert-message-dialog-title">
                {{data.title}}
            </span>
            <span class="cursor--pointer" (click)="onNoClick()">
                <img src="../../../../../assets/images/close-button.svg" />
            </span>
        </div>
        <div [ngClass]="{'visibility--hidden':!isError}" class="dialog-error-message" style="word-break: normal;">
            Your entered password is incorrect. Please try again.
        </div>
        <div class="alert-dialog-content" style="display:flex;flex-direction: column;align-items: stretch;">
                <form [formGroup]="passwordForm" style="display:flex;flex-direction: column;align-items: stretch;">
                    <mat-form-field [hideRequiredMarker]="true" style="flex:1 1" floatLabel="always"  class="form-field">
                        <mat-label class="dialog-form--label">New password</mat-label>
                        <input class="text-color--dark-grey input-padding--top" matInput formControlName='magic' [type]="hide ? 'password' : 'text'" autocomplete="off">
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" class="eyes-icon--button">
                            <!-- <mat-icon class="text-color--dark-grey eyes-icon" [ngClass]="{'material-icons-outlined':hide}">visibility</mat-icon> -->
                            <div [ngClass]="{'eyes-icon-view-no':!!hide,'eyes-icon-view':!hide}"></div>
                        </button>
                    </mat-form-field>
                    
                </form>

        </div>
        <div class="alert-dialog-content" style="display:flex;flex-direction: row-reverse;justify-content: space-between;">
            <button  class="button--save-changes"  type="button" (click)="changePassword()" [disableRipple]="true" mat-raised-button>Save Changes</button>
        </div>
    </div>

import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MenuScrollService{
    menuItem$=new BehaviorSubject<number>(-1);
    listIndex$ = new BehaviorSubject<listIndex>({index:-1});

    setMenuIndex(index:number){
        this.menuItem$.next(index);
    }

    getMenuIndex(){
        return this.menuItem$.asObservable();
    }

    resetMenuIndex(){
        this.menuItem$.next(-1);
    }

    setListIndex(index:number){
        console.log(index);
        this.listIndex$.next({index:index});
    }

    getListIndex(){
        return this.listIndex$.asObservable();
    }

    resetListIndex(){
        this.listIndex$.next({index:-1});
    }

}

interface listIndex{
    index:number,
    menu?:string
}
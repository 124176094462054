import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiException } from 'src/app/shared/models/api-exception.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService,private router:Router) {  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError(error => this.errorHandler(error))
      );
  }

  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<ApiException>> {
      const { error, message, status, url } = response;

      const responseError: ApiException = {
          httpStatus: status,
          errorMessage: message,
          errorUrl: url,
          message: message,
          resource: message,
          exceptionType: error ?  error.exceptionType : null,
          exceptionMessage: error ? error.message : null,
          exceptionMethod: error ? error.resource : null,
      };
      this.errorService.updateErrorMessage(responseError);
      if(responseError.httpStatus==401){
        this.router.navigate(['/login']);
      }
      throw responseError;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleLabelDirective } from './directives/role-label.directive';
import { DynamicComponentDirective } from './directives/dynamicComponent.directive';
import { ScrollMenuDirective } from './directives/scroll-menu.directive';
import { ScrollMenuItemDirective } from './directives/scroll-menu-item.directive';



@NgModule({
  declarations: [RoleLabelDirective,DynamicComponentDirective,ScrollMenuDirective,ScrollMenuItemDirective],
  imports: [
    CommonModule
  ],
  exports:[
    RoleLabelDirective,DynamicComponentDirective,ScrollMenuDirective,ScrollMenuItemDirective
  ]
})
export class DirectiveModule { }

import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AngularEditorConfig, AngularEditorModule, AngularEditorService, AngularEditorComponent } from '@kolkov/angular-editor';
import { FormControl, FormGroup } from '@angular/forms';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  @ViewChild("editor") editor: any;

  @Input() control = new FormControl('');
  @Input() labelName = '';
  isFocus = false;
  @Input() haveToolbar = false;

  @Input() form!: FormGroup;
  @Input() controlName = '';
  @Input() id = '';

  cp = false
  @Input() config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: this.haveToolbar,
    placeholder: "",
    defaultParagraphSeparator: 'standard',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['italic',
        'fontName'],
      ['fontSize',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };


  @Input() noConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: this.haveToolbar,
    placeholder: "",
    defaultParagraphSeparator: 'standard',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['italic',
        'fontName'],
      ['fontSize',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };


  getConfig(): any {
    console.log(this.editor);
    // if (this.editor.modeVisual == false) {
    //   this.editor.modeVisual = true;
    //   this.editor.editorToolbar.triggerCommand("toggleEditorMode");
    // }


    if (this.isFocus) {
      return this.config
    } else {
      return this.noConfig
    }
  }

  try() {
    console.log(this.editor);
  }

  constructor() {


  }

  ngOnInit(): void {
    if (this.haveToolbar) {
      this.config.showToolbar = true
    }
  }

  changePre(event: any) {
    this.cp = false
    console.log(this.editor.editorService.savedSelection.startOffset)
    if (this.form.get(this.controlName)?.value) {
      let removePre = this.form.get(this.controlName)?.value.replace("<pre>", '').replace("</pre>", '').replace("&lt;pre&gt;", '').replace("&lt;/pre&gt;", '');
      this.form.get(this.controlName)?.setValue(removePre)
    }

    this.cp = true
  }

}


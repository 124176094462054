import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, Input, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, AfterViewInit {

  @ViewChildren('codes') items: QueryList<ElementRef>;
  @Input() otp: FormArray;
  @Output() otpChange = new EventEmitter<string>();

  constructor(private cdRef: ChangeDetectorRef) { }
  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.otp.valueChanges.subscribe((val) => {
      if (val.filter(x => !!x).length === val.length) {
        this.otpChange.emit(val.join(''));
      }
    });
    this.items.first.nativeElement.focus();
    this.cdRef.detectChanges();
  }


  validateAndMoveToNextCode(event, index) {
    const codes = this.items.toArray();
    if (codes[index].nativeElement.value) {
      return false;
    }
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      this.otp.controls[index].setValue(event.key);
    } else {
      return false;
    }

    codes[index + 1]?.nativeElement.focus();
  }



  backToPreviousCode(event, index) {
    const codes = this.items.toArray();
    if (event.keyCode !== 8) {
      return;
    }
    this.otp.controls[index]?.reset();
    codes[index - 1]?.nativeElement.focus();
  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss']
})
export class ReasonDialogComponent implements OnInit {

  form = this.fb.group({
    reason: ['', [Validators.required]],
  })
  title = ""
  constructor(public dialogRef: MatDialogRef<ReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string },
    private fb: FormBuilder,) {
      this.dialogRef.disableClose = true;
      this.title = this.data.title ? this.data.title : "Please input reason."

     }

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.form.markAllAsTouched()
    if (this.form.valid) {

      this.dialogRef.close({
        reason: this.form.controls.reason.value
      });
    }
  }
}

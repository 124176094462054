import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { startWith } from 'rxjs/operators';
import { AdminRoleFactory } from 'src/app/core/services/role/admin-role.factory';
import { AdminRole } from '../../models/role/base/admin-role.model';
import { SuperAdmin } from '../../models/role/base/super-admin.model';
import { Admin } from '../../models/role/base/admin.model';
import { AdminRoleType } from '../../enums/role/admin-role-type.enum';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {
  @Input() displayName = '';
  @Input() displayIconUrl = '../../../../assets/images/basic-info-icon.png';
  @Input() birthDay = '';
  @Input() birthMonth = '';
  @Input() email = '';
  @Input() mobileNumber = '';
  @Input() isEditable = false;
  @Input() isProfile = true;
  @Input() countryOptions = [];
  @Input() countryId = '';
  @Input() roleOptions = [];
  @Input() role = 2;
  
  @Output() changePasswordClicked = new EventEmitter<boolean>();
  @Output() resetPasswordClicked = new EventEmitter<boolean>();
  @Output() editClicked = new EventEmitter<boolean>();
  @Output() basicInfoChanged = new EventEmitter<{
    displayName: '',
    birthDay: '',
    birthMonth: '',
    email: '',
    countryId: '',
    mobileNumber: '',
    role: -1
  }>();

  monthOptions = moment.monthsShort().map((m, i) => {
    return {
      value: i + 1,
      viewValue: m
    };
  });
  basicInfoForm: FormGroup;
  selectedMonth = '';
  selectedRole: AdminRole;
  isFormValid = true;
  AdminRoleType = AdminRoleType;
  constructor(private fb: FormBuilder, private adminRoleFactory: AdminRoleFactory) { }


  ngOnInit(): void {
    console.log(this.monthOptions);

    this.basicInfoForm = this.fb.group({
      displayName: [''],
      birthDay: [''],
      birthMonth: [''],
      email: [''],
      countryId: [''],
      mobileNumber: [''],
      role: ['']
    });
    this.selectedRole = this.adminRoleFactory.createRole(this.role);

    
   
  }

  editBasicInfo() {
    this.isEditable = true;
    this.basicInfoForm.patchValue({
      displayName: this.displayName,
      birthDay: this.birthDay,
      birthMonth: this.birthMonth,
      email: this.email,
      countryId: this.countryId,
      mobileNumber: this.mobileNumber,
      role: this.role,
    });

    console.log({
      displayName: this.displayName,
      birthDay: this.birthDay,
      birthMonth: this.birthMonth,
      email: this.email,
      countryId: this.countryId,
      mobileNumber: this.mobileNumber,
      role: this.role,
    });
    
  }

  cancel() {
    this.isEditable = false;
    this.basicInfoForm.reset();
    this.removeValidation();
  }

  saveChanges() {
    console.log(this.basicInfoForm.valid);
    this.setValidation();
    this.basicInfoForm.markAllAsTouched();
    if (this.basicInfoForm.valid) {
      this.isEditable = false;
      this.selectedMonth = this.monthOptions.find(m => m.value === this.birthMonthInput.value)?.viewValue;
      
      this.basicInfoChanged.emit(this.basicInfoForm.getRawValue());
      this.selectedRole = this.adminRoleFactory.createRole(this.roleInput.value);
      console.log(this.basicInfoForm.value);
      
      this.basicInfoForm.reset();
      this.removeValidation();
      
    }
  }

  setValidation() {
    this.birthDayInput.setValidators([Validators.compose([
      Validators.pattern('^([1-9])$|^([1-2][0-9])$|^([3][0-1])$'), Validators.required
    ])]);
    this.mobileNumberInput.setValidators([Validators.required]);
    this.birthDayInput.updateValueAndValidity();
    this.mobileNumberInput.updateValueAndValidity();
  }

  removeValidation() {
    this.birthDayInput.clearValidators();
    this.mobileNumberInput.clearValidators();
    this.birthDayInput.updateValueAndValidity();
    this.mobileNumberInput.updateValueAndValidity();

  }

  get displayNameInput() { return this.basicInfoForm.get('displayName'); }
  get emailInput() { return this.basicInfoForm.get('email'); }
  get mobileNumberInput() { return this.basicInfoForm.get('mobileNumber'); }
  get countryIdInput() { return this.basicInfoForm.get('countryId'); }
  get birthDayInput() { return this.basicInfoForm.get('birthDay'); }
  get birthMonthInput() { return this.basicInfoForm.get('birthMonth'); }
  get roleInput() { return this.basicInfoForm.get('role'); }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSearchBoxComponent } from './filter-search-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';



@NgModule({
  declarations: [FilterSearchBoxComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatListModule
  ],
  exports:[
    FilterSearchBoxComponent
  ]
})
export class FilterSearchBoxModule { }

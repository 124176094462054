import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-mat-select-search-input',
  templateUrl: './mat-select-search-input.component.html',
  styleUrls: ['./mat-select-search-input.component.scss'],
})
export class MatSelectSearchInputComponent implements OnInit, OnChanges {
  @Input() options: any[];
  @Input() fieldName: string;
  @Output() onKeyFilter = new EventEmitter();
  optionsCopy = [];
  searchQuery: string = '';

  constructor() {}

  ngOnInit(): void {}

  onKey(value: string) {
    const filterRtxCityList = this.search(value);
    this.onKeyFilter.emit(filterRtxCityList);
  }

  search(value: string) {
    this.optionsCopy = [...this.options];
    let filter = value.toLowerCase();
    return this.optionsCopy.filter((option) =>
      this.fieldName != null
        ? option[this.fieldName].toLowerCase().startsWith(filter)
        : option.toLowerCase().startsWith(filter)
    );
  }

  clearSearchQuery() {
    this.searchQuery = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { shouldClearSearchQuery } = changes;
    if (shouldClearSearchQuery) {
      this.clearSearchQuery()
    }
  }
}
